import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckboxInput, ConnectedForm, StaticDatePickerInput, TextInput } from '../common/form-controller';
import {
  BtnPrimary,
  Chip,
  Form,
  Header,
  Icons,
  Info,
  Link,
  SectionContent,
  Subtitle,
  SuccessRemoteDataToast,
  TextField,
} from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { Label } from '../common/ui-component/typography';
import CustomerService from '../customer/customer.service';
import OrderFileService from '../files/order-files.service';
import { CUSTOMERS_ROUTE, MEDICAL_CASE_ROUTE, ORDERS_ROUTE, SUBSCRIPTIONS_ROUTE } from '../Router';
import { DateHelper } from '../utils/date';
import { isSuccess, map, map2, notAsked, RemoteData, success, withDefault } from '../utils/remote-data';
import Result from '../utils/result/result';
import MedicalCaseService from './medical-case.service';
import CaseCreatorService from '../case-creator/case-creator.service';
import QuestionHelper, { QuestionForm } from './question.helper';
import MedicalCaseValidator from './medical-case.validator';
import { ConfirmRoutedModal, RoutedModal } from '../common/ui-component/modals';
import Section from '../common/ui-component/layouts/Section.v2';
import Tooltips from '../common/ui-component/tooltips/Tooltips.v2';
import { Btn } from '../common/ui-component/buttons';
import { Box, Container, List, ListItem, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { _databaseFormat } from '../utils/date/helper';
import { isAdminUser } from '../authentication/auth';
import { useAccessToken } from '../authentication';
import SubscriptionsService from '../subscriptions/subscriptions.service';
import PrescriptionTable from './PrescriptionTable';
import PhotosSection from '../files/PhotosSection';
import HealthUpdateHelper from '../health-update-tracker/health-update.helper';
import { History } from '../common/history';
import MedicalCaseHistoryService from './medical-case-history.service';
import { CreateCasePayload, MedicalCaseHistory, MedicalCaseHistoryDTO, MedicalCaseModel } from '../models';
import { MdiService } from '../mdi';
import { red } from '@mui/material/colors';
import { FeatureToggleHelper } from '../utils/feature-toggle';

const newCaseHash = '#new-case';
const forceNewCaseHash = '#force-new-case';
const editLastHealthUpdateHash = '#edit-last-health-update';
const editHealthUpdateDueDateHash = '#edit-health-update-due-date';
const syncHash = '#sync';

const historyHash = '#history';

const DetailPage = () => {
  const { id } = useParams();
  const [user] = useAccessToken();

  const navigate = useNavigate();
  const currentRoute = `${MEDICAL_CASE_ROUTE}/${id}`;

  const { t } = useTranslation('medical_case');

  const [fetchMedicalCase, rMedicalCase] = MedicalCaseService.useGetMedicalCase();

  const [patchMedicalCase] = MedicalCaseService.usePatch();

  const [syncOrders, rSyncOrders, setRSyncOrders] = MedicalCaseService.useSyncOrders();

  const [fetchSignedUrl, rSignedUrl, setSignedUrls] = OrderFileService.useGetSignedUrls();

  const [syncCustomer, rSyncResult, setRSyncResult] = CustomerService.useSyncById();

  const [getIdentityHash, rIdentityHash] = CustomerService.useGetCustomerIdentityHash();

  const [syncSubscription, rSyncSubscription, setRSyncSubcription] = SubscriptionsService.useSyncSubscription();

  const [syncPhotos, rSyncPhotos, setRSSyncPhotos] = OrderFileService.useSyncPhotosByCustomerId();

  const [putMedicalInformation, rUpdatedMedicalInformation, setRUpdatedMedicalInformation] =
    MedicalCaseService.useUpdateMedicalInformation();

  const [createNewCase, rNewCase, setRNewCase] = CaseCreatorService.useCreateNewCase();

  const [forceCreateNewCase, rNewForcedCase, setRNewForcedCase] = CaseCreatorService.useForceCreateNewCase();

  const [pushToNextStep, rPushResult, setRPushResult] = MedicalCaseService.usePushToNextStep();

  const [deleteFile, rDeleteFile, setDeleteResponse] = MedicalCaseService.useDeleteFile();

  const [syncMedicalCase, rSyncMedicalCase, setSyncMedicalCaseResult] = MedicalCaseService.useSyncMedicalCase();

  const [getHistory, rHistory] = MedicalCaseHistoryService.useGetHistory();

  const [overrideMdiCase, rOverrideMdiCase, setMdiCase] = MdiService.useUpdateCaseLockId();

  useEffect(() => {
    (async () => {
      const m = await fetchMedicalCase(id);
      const photos = map((x: MedicalCaseModel) =>
        (x.photos || [])
          .map((p) => p.s3File?.key)
          .filter(Boolean)
          .map((o) => o || ''),
      )(m);
      const ids = withDefault<string[]>([])(photos);
      if (isSuccess(m)) {
        getHistory(m.value.id);
        getIdentityHash(m.value.customerId);
      }

      if (ids.length) fetchSignedUrl(ids);
      else setSignedUrls(success([]));
    })();
  }, [fetchMedicalCase, fetchSignedUrl, id, setSignedUrls, setDeleteResponse, getHistory, getIdentityHash]);

  const mappedMedicalCase = useMemo(() => {
    return map2((medicalCase: MedicalCaseModel) => (signedUrls: { id: string; url: string }[]) => {
      const result: MedicalCaseModel = {
        ...medicalCase,
        photos: (medicalCase.photos || []).map((p) => ({
          created: p.created,
          id: p.id,
          type: p.type,
          woocommerceCustomerId: p.woocommerceCustomerId,
          woocommerceOrderId: p.woocommerceOrderId,
          caseId: p.caseId,
          customerId: p.customerId,
          mdiFile: p.mdiFile,
          s3File: p.s3File
            ? {
                Bucket: p.s3File.Bucket,
                ETag: p.s3File.ETag,
                Key: p.s3File.Key,
                Location: p.s3File.Location,
                key: p.s3File.key,
                signedUrl: signedUrls.find((x) => x.id === p.s3File?.key)?.url || p.s3File?.signedUrl,
              }
            : undefined,
        })),
      };
      return result;
    })(rMedicalCase)(rSignedUrl);
  }, [rMedicalCase, rSignedUrl]);

  const handleEditHealthUpdateDueDateSubmit = (
    payload: { healthUpdateDueDate: DateTime },
    medicalCaseId: number,
    onClose: () => void,
  ) => {
    patchMedicalCase({
      id: medicalCaseId,
      medicalCase: {
        healthUpdateDueDate: DateHelper.trickDatetime(payload.healthUpdateDueDate).toUTC().toFormat(_databaseFormat),
      },
    }).then(() => {
      onClose();
      fetchMedicalCase(medicalCaseId.toString());
    });
  };

  return (
    <Container maxWidth="xl">
      <RemoteDataContent value={mappedMedicalCase}>
        <RemoteDataContent.Success>
          {(medicalCase: MedicalCaseModel) => {
            const hasLicensePhoto =
              medicalCase.driverLicenseRequired &&
              (medicalCase.photos || []).filter((x) => x.type === 'license').length;

            const hasHairPhotos = (medicalCase.photos || []).filter((x) => x.type === 'other').length;

            const hasPhotos =
              (medicalCase.photos || []).every((x) => !!x.mdiFile?.name) && (medicalCase.photos || []).length;

            const questions = Object.values(medicalCase.medicalInformation || {}) || [];
            const hasAllProductsIdle = medicalCase.claimedProducts.every((p) => p.isIdle);

            const hasMedicalInformation = questions.length && questions.every((x) => !!x.value);
            const hasAtLeastOneQuestionFill = questions.length && questions.some((x) => !!x.value);
            const errors = MedicalCaseValidator.getMedicalCaseErrors(medicalCase, t);
            const isAdmin = isAdminUser(user);

            const needsToCompleteHUFBadgeInfo = HealthUpdateHelper.getNeedsToCompleteHUFBadgeInfo({
              healthUpdateDueDate: medicalCase.healthUpdateDueDate,
              lastHealthUpdate: medicalCase.lastHealthUpdate,
            });
            const title = (
              <>
                {t('medicalCase')} #{medicalCase.id}
                <Btn
                  sx={{
                    width: '24px',
                    minWidth: 0,
                    margin: 0,
                    paddingLeft: '15px',
                    paddingRight: '20px',
                    marginRight: '5px',
                    marginLeft: '5px',
                  }}
                  onClick={() => navigate(`${currentRoute}${syncHash}`)}
                >
                  <Tooltip title={t('syncMedicalCase')}>
                    <Icons.Sync style={{ paddingBottom: '2px', paddingLeft: '5px' }} />
                  </Tooltip>
                </Btn>
                {medicalCase.firstName} {medicalCase.lastName}
              </>
            );
            return (
              <>
                <Header title={title}>
                  <Box>
                    <Link href={historyHash}>{t('history')}</Link>
                  </Box>
                </Header>

                <MedicalCaseErrors errors={medicalCase.errors} />
                <Section>
                  <Section.Title>{t('caseInformation')}</Section.Title>
                  <SectionContent>
                    <Info label={t('patientId')}>
                      {medicalCase.patientId && (
                        <Link
                          href={`${process.env.REACT_APP_MDI_ADMIN_URL}/resources/patients/${medicalCase.patientId}`}
                        >
                          {medicalCase.patientId}
                        </Link>
                      )}
                    </Info>
                    <Info label={t('lastCaseId')}>
                      {medicalCase.latestCaseId && (
                        <Link href={`${process.env.REACT_APP_MDI_URL}/tabs/cases/${medicalCase.latestCaseId}`}>
                          {medicalCase.latestCaseId}
                        </Link>
                      )}
                    </Info>
                    <Info label={t('caseIdList')}>
                      <List>
                        {(medicalCase.caseIds || []).map((cid) => {
                          return (
                            <ListItem key={cid} sx={{ padding: 0, margin: 0 }}>
                              <Link href={`${process.env.REACT_APP_MDI_URL}/tabs/cases/${cid}`}>{cid}</Link>
                              {FeatureToggleHelper.isEnable(process.env.REACT_APP_ALLOW_LOCK_ID_CHANGE) && (
                                <Tooltip title="Override lock id">
                                  <Box>
                                    <Btn onClick={() => overrideMdiCase({ caseId: cid })}>
                                      <Icons.RefreshCircle sx={{ color: red[500] }} />
                                    </Btn>
                                  </Box>
                                </Tooltip>
                              )}
                            </ListItem>
                          );
                        })}
                      </List>
                    </Info>
                  </SectionContent>
                  <SectionContent>
                    <Info label={t('lastEncounter')}>
                      <Info.DateTime>{medicalCase.lastEncountered || ''}</Info.DateTime>
                    </Info>
                  </SectionContent>
                </Section>

                <Section>
                  <Section.Title>{t('annualHealthUpdate')}</Section.Title>
                  <SectionContent>
                    <Info label={t('status')}>
                      <Chip text={needsToCompleteHUFBadgeInfo.text} color={needsToCompleteHUFBadgeInfo.color} />
                    </Info>
                    <Info
                      label={
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <span style={{ display: 'block' }}>{t('healthUpdateDueDate')}</span>
                          {isAdmin && (
                            <Btn sx={{ padding: 0, width: '1rem' }}>
                              <Link style={{ display: 'block', width: '100%' }} href={editHealthUpdateDueDateHash}>
                                ✎
                              </Link>
                            </Btn>
                          )}
                        </Box>
                      }
                    >
                      {medicalCase.healthUpdateDueDate ? (
                        <Info.DateTime>{medicalCase.healthUpdateDueDate}</Info.DateTime>
                      ) : (
                        '-'
                      )}
                    </Info>
                    <Info
                      label={
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <span style={{ display: 'block' }}>{t('lastHufSubmission')}</span>
                          <Btn sx={{ padding: 0, width: '1rem' }}>
                            <Link style={{ display: 'block', width: '100%' }} href={editLastHealthUpdateHash}>
                              ✎
                            </Link>
                          </Btn>
                        </Box>
                      }
                    >
                      <Info.DateTime>{medicalCase.lastHealthUpdate || ''}</Info.DateTime>
                    </Info>
                    <Info label={t('medicalInformationUpdated')}>
                      <Info.DateTime>{medicalCase.medicalInformationUpdated || ''}</Info.DateTime>
                    </Info>
                    <Info label={t('lastAnnualRenewal')}>
                      <Info.DateTime>{medicalCase.lastAnnualRenewal || '-'}</Info.DateTime>
                    </Info>
                  </SectionContent>
                </Section>
                <Section>
                  <Section.Title>{t('prescriptionInformation')}</Section.Title>
                  <Section.Actions>
                    <Tooltips>
                      {medicalCaseErrorsTooltips(errors)}
                      {isAdmin && (
                        <Tooltips.Content>
                          <BtnPrimary onClick={() => navigate(forceNewCaseHash)} disabled={Boolean(errors.length)}>
                            {t('forceNewCase')}
                          </BtnPrimary>
                        </Tooltips.Content>
                      )}
                    </Tooltips>
                    <Tooltips>
                      {medicalCaseErrorsTooltips(errors)}
                      <Tooltips.Content>
                        <BtnPrimary onClick={() => navigate(newCaseHash)} disabled={Boolean(errors.length)}>
                          {t('newCase')}
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                  </Section.Actions>
                  <PrescriptionTable customerId={medicalCase.customerId} />
                </Section>
                <Section>
                  <Section.Title>{t('orderInfo')}</Section.Title>
                  <SectionContent>
                    <Info label={t('created')}>
                      <Info.DateTime>{medicalCase.createdAt}</Info.DateTime>
                    </Info>
                    <Info label={t('updated')}>
                      <Info.DateTime>{medicalCase.updatedAt}</Info.DateTime>
                    </Info>
                  </SectionContent>
                  <SectionContent>
                    <Info
                      label={
                        <span style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                          {t('orderIds')}
                          <Box>
                            <Btn onClick={() => syncOrders({ id: medicalCase.id })}>
                              <Icons.Sync />
                            </Btn>
                          </Box>
                        </span>
                      }
                    >
                      <Info.Conditional conditional={!!medicalCase.orderIds?.length}>
                        <Info.Conditional.True>
                          <Info.MultipleLine>
                            {(medicalCase?.orderIds || []).map((orderId, i) => (
                              <Link key={orderId} href={`${ORDERS_ROUTE}/${orderId}`}>
                                {orderId}
                              </Link>
                            ))}
                          </Info.MultipleLine>
                        </Info.Conditional.True>
                        <Info.Conditional.False>
                          <BtnPrimary onClick={() => pushToNextStep(medicalCase.id)} sx={{ width: 120 }}>
                            {t('syncOrder')}
                          </BtnPrimary>
                        </Info.Conditional.False>
                      </Info.Conditional>
                    </Info>
                    <Info label={t('subscriptionId')}>
                      {medicalCase.subscriptionIds.map((subId) => (
                        <>
                          <Link key={subId} href={`${SUBSCRIPTIONS_ROUTE}/${subId}`}>
                            {subId}
                          </Link>
                          <Btn
                            sx={{ width: '24px', minWidth: 0, margin: 0, padding: 0 }}
                            onClick={() => syncSubscription(subId)}
                          >
                            <Icons.Sync />
                          </Btn>
                        </>
                      ))}
                    </Info>
                  </SectionContent>
                  <SectionContent>
                    <Label underline>{t('products')}</Label>
                  </SectionContent>
                  <>
                    {medicalCase.claimedProducts.map((product) => (
                      <SectionContent key={product.sku}>
                        <Info label={t('sku')}>{product.sku}</Info>
                        <Info label={t('notIdle')}>{product.isIdle ? <Icons.Cancel /> : <Icons.Checked />}</Info>
                        <Info label={t('subscriptionStatus')}>
                          <Chip text={product.subscriptionStatus} color={product.isIdle ? 'warning' : 'success'} />
                        </Info>
                        <Info label={t('pharmacy')}>
                          <Info.Pharmacy>{product.pharmacyId}</Info.Pharmacy>
                        </Info>
                      </SectionContent>
                    ))}
                  </>
                </Section>
                <Section>
                  <Section.Title>
                    <Link href={`${CUSTOMERS_ROUTE}/${medicalCase.customerId}`}>
                      <Subtitle>
                        {t('customer')} #{medicalCase.customerId}
                      </Subtitle>
                    </Link>
                  </Section.Title>

                  <Section.Actions>
                    <span>
                      <BtnPrimary type="button" onClick={() => syncCustomer(medicalCase.customerId)}>
                        {t('syncCustomer')}
                      </BtnPrimary>
                    </span>
                    <Tooltips>
                      <Tooltips.Multiple>
                        <Tooltips.Conditional predicate={!medicalCase.orderIds?.length}>
                          {t('missingOrders')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!!medicalCase.patientId}>
                          {t('hasPatientId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!!hasPhotos}>{t('hasPhotos')}</Tooltips.Conditional>
                      </Tooltips.Multiple>
                      <Tooltips.Content>
                        <BtnPrimary
                          type="button"
                          onClick={() => syncPhotos(medicalCase.customerId)}
                          disabled={Boolean(medicalCase.patientId || hasPhotos)}
                        >
                          {t('syncPhotos')}
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                    <Tooltips>
                      <Tooltips.Multiple>
                        <Tooltips.Conditional predicate={!!medicalCase.patientId}>
                          {t('hasPatientId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!!medicalCase.driverLicenseId}>
                          {t('hasDriverLicenseId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!hasLicensePhoto}>
                          {t('missingDriverLicensePhoto')}
                        </Tooltips.Conditional>
                      </Tooltips.Multiple>
                      <Tooltips.Content>
                        <BtnPrimary
                          type="button"
                          onClick={() => pushToNextStep(medicalCase.id)}
                          disabled={Boolean(medicalCase.patientId || medicalCase.driverLicenseId || !hasLicensePhoto)}
                        >
                          {t('syncDriverLicense')}
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                    <Tooltips>
                      <Tooltips.Multiple>
                        <Tooltips.Conditional predicate={!!medicalCase.patientId}>
                          {t('hasPatientId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!medicalCase.driverLicenseId}>
                          {t('missingDriverLicenseId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!hasLicensePhoto}>
                          {t('missingDriverLicensePhoto')}
                        </Tooltips.Conditional>
                      </Tooltips.Multiple>
                      <Tooltips.Content>
                        <BtnPrimary
                          type="button"
                          onClick={() => pushToNextStep(medicalCase.id)}
                          disabled={Boolean(
                            medicalCase.patientId ||
                              (!medicalCase.driverLicenseId && medicalCase.driverLicenseRequired) ||
                              !medicalCase.dateOfBirth ||
                              !hasHairPhotos,
                          )}
                        >
                          Create Patient
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                  </Section.Actions>
                  <SectionContent>
                    <Info label={t('firstName')}>{medicalCase.firstName}</Info>
                    <Info label={t('lastName')}>{medicalCase.lastName}</Info>
                    <Info label={t('dateOfBirth')}>
                      {Result.withDefault('--')(DateHelper.onlyDateOfBirth(medicalCase.dateOfBirth))}
                    </Info>
                    <Info label={t('email')}>
                      <Info.Email>{medicalCase.email}</Info.Email>
                    </Info>
                  </SectionContent>
                  <SectionContent>
                    <Info label={t('gender')}>
                      <Info.Gender>{medicalCase.gender}</Info.Gender>
                    </Info>
                    <Info label={t('pregnancy')}>
                      <Info.YesNo>{medicalCase.pregnancy}</Info.YesNo>
                    </Info>
                  </SectionContent>
                  <SectionContent>
                    <Info label={t('address1')}>{medicalCase.address?.address || '--'}</Info>
                    <Info label={t('address2')}>{medicalCase.address?.address2 || '--'}</Info>
                  </SectionContent>
                  <SectionContent>
                    <Info label={t('city')}>{medicalCase.address?.cityName || '--'}</Info>
                    <Info label={t('state')}>{medicalCase.address?.stateName || '--'}</Info>
                    <Info label={t('postcode')}>{medicalCase.address?.zipCode || '--'}</Info>
                    <Info label={t('phoneNumber')}>
                      <Info.Phone>{medicalCase.phoneNumber}</Info.Phone>
                    </Info>
                  </SectionContent>
                </Section>
                <PhotosSection
                  customerId={medicalCase.customerId}
                  orderIds={medicalCase.orderIds}
                  pageUrl={`${MEDICAL_CASE_ROUTE}/${id}`}
                  photos={medicalCase.photos}
                  onDeletePhoto={(fileId: number) => {
                    deleteFile({ medicalCaseId: medicalCase.id, fileId: fileId });
                  }}
                  proveVerified={!medicalCase.driverLicenseRequired}
                  idRequired={medicalCase.driverLicenseRequired && !medicalCase.videoRequired}
                  videoRequired={medicalCase.videoRequired}
                />
                <Section>
                  <Section.Title>{t('medicalInformation')}</Section.Title>
                  <Section.Actions>
                    <RemoteDataContent value={rIdentityHash}>
                      <RemoteDataContent.Success>
                        {(hash: string) => {
                          const encodedHash = encodeURIComponent(hash);
                          // eslint-disable-next-line max-len
                          const linkToHuf = `${process.env.REACT_APP_WOOCOMMERCE_BASE_URL}/health-update?h=${encodedHash}`;
                          return (
                            <Tooltips>
                              <Tooltips.Single>
                                <span>{t('annualHealthUpdateFormIncognito')}</span>
                              </Tooltips.Single>
                              <Tooltips.Content>
                                <Link to={linkToHuf}>
                                  <BtnPrimary>{t('huf')}</BtnPrimary>
                                </Link>
                              </Tooltips.Content>
                            </Tooltips>
                          );
                        }}
                      </RemoteDataContent.Success>
                      <RemoteDataContent.NotAsked>
                        <></>
                      </RemoteDataContent.NotAsked>
                    </RemoteDataContent>
                    <Tooltips>
                      <Tooltips.Multiple>
                        <Tooltips.Conditional predicate={!!hasPhotos}>{t('hasPhotos')}</Tooltips.Conditional>
                      </Tooltips.Multiple>
                      <Tooltips.Content>
                        <BtnPrimary
                          type="button"
                          onClick={() => syncPhotos(medicalCase.customerId)}
                          disabled={Boolean(hasPhotos)}
                        >
                          {t('syncPhotos')}
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                    <Tooltips>
                      <Tooltips.Multiple>
                        <Tooltips.Conditional predicate={!!hasAtLeastOneQuestionFill}>
                          {t('hasMedicalQuestionFilled')}
                        </Tooltips.Conditional>
                      </Tooltips.Multiple>
                      <Tooltips.Content>
                        <BtnPrimary
                          type="button"
                          onClick={() => pushToNextStep(medicalCase.id)}
                          disabled={Boolean(hasAtLeastOneQuestionFill)}
                        >
                          {t('syncMedicalQuestion')}
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                    <Tooltips>
                      <Tooltips.Multiple>
                        <Tooltips.Conditional predicate={!!medicalCase.latestCaseId}>
                          {t('hasCaseId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!hasMedicalInformation}>
                          {t('missingMedicalInformation')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!medicalCase.patientId}>
                          {t('missingPatientId')}
                        </Tooltips.Conditional>
                        <Tooltips.Conditional predicate={!hasPhotos}>{t('missingHairPhotos')}</Tooltips.Conditional>
                        <Tooltips.Conditional predicate={hasAllProductsIdle}>
                          {t('allProductsAreIdle')}
                        </Tooltips.Conditional>
                      </Tooltips.Multiple>
                      <Tooltips.Content>
                        <BtnPrimary
                          type="button"
                          onClick={() => pushToNextStep(medicalCase.id)}
                          disabled={Boolean(
                            medicalCase.latestCaseId ||
                              !hasMedicalInformation ||
                              !hasPhotos ||
                              !medicalCase.patientId ||
                              hasAllProductsIdle,
                          )}
                        >
                          {t('createCase')}
                        </BtnPrimary>
                      </Tooltips.Content>
                    </Tooltips>
                  </Section.Actions>

                  <ConnectedForm<QuestionForm>
                    id="hook-form"
                    onSubmit={(medicalInformation: QuestionForm) =>
                      putMedicalInformation({
                        id: medicalCase.id,
                        medicalInformation: QuestionHelper.toAnwser(medicalInformation),
                      })
                    }
                    defaultValues={QuestionHelper.toQuestionForm(medicalCase.medicalInformation)}
                  >
                    {medicalCase.medicalInformation.map(({ key, value, longName }) => {
                      return (
                        <Form.Section title={longName} key={key}>
                          <Form.Section.Row>
                            <Form.Section.Row.Field md={3}>
                              <TextInput errorIfEmpty name={`${key}.value`} label={t('value')} />
                            </Form.Section.Row.Field>
                            <Form.Section.Row.Field md={9}>
                              <TextInput name={`${key}.description`} label={t('description')} />
                            </Form.Section.Row.Field>
                          </Form.Section.Row>
                        </Form.Section>
                      );
                    })}
                    <Form.Section title="">
                      <Form.Section.Row>
                        <BtnPrimary type="submit">{t('save')}</BtnPrimary>
                      </Form.Section.Row>
                    </Form.Section>
                  </ConnectedForm>
                </Section>
                {isAdmin && (
                  <>
                    <RoutedModal
                      predicatedHash={historyHash}
                      defaultUrl={currentRoute}
                      title={t('history')}
                      maxWidth="xl"
                    >
                      <RemoteDataContent value={rHistory}>
                        <RemoteDataContent.Success>
                          {(history: MedicalCaseHistory[]) => (
                            <History<MedicalCaseHistoryDTO> history={history}></History>
                          )}
                        </RemoteDataContent.Success>
                        <RemoteDataContent.NotAsked>
                          <></>
                        </RemoteDataContent.NotAsked>
                      </RemoteDataContent>
                    </RoutedModal>
                    <RoutedModal
                      predicatedHash={editHealthUpdateDueDateHash}
                      defaultUrl={currentRoute}
                      title={t('editHealthUpdateDueDate')}
                    >
                      {(onClose: () => void) => (
                        <ConnectedForm
                          id={editHealthUpdateDueDateHash}
                          onSubmit={(payload: { healthUpdateDueDate: DateTime }) =>
                            handleEditHealthUpdateDueDateSubmit(payload, medicalCase.id, onClose)
                          }
                          defaultValues={{
                            healthUpdateDueDate: medicalCase.healthUpdateDueDate
                              ? DateTime.fromFormat(medicalCase.healthUpdateDueDate, _databaseFormat)
                              : undefined,
                          }}
                        >
                          <Form.Section title="">
                            <Form.Section.Row>
                              <StaticDatePickerInput name="healthUpdateDueDate" />
                            </Form.Section.Row>
                          </Form.Section>
                          <Form.Section title="">
                            <Form.Section.Row>
                              <BtnPrimary type="submit">{t('save')}</BtnPrimary>
                            </Form.Section.Row>
                          </Form.Section>
                        </ConnectedForm>
                      )}
                    </RoutedModal>
                  </>
                )}
                <RoutedModal
                  predicatedHash={editLastHealthUpdateHash}
                  defaultUrl={currentRoute}
                  title={t('editLastHufSubmission')}
                >
                  {(onClose: () => void) => (
                    <ConnectedForm
                      id={editLastHealthUpdateHash}
                      onSubmit={(x) => {
                        patchMedicalCase({
                          id: medicalCase.id,
                          medicalCase: {
                            lastHealthUpdate: DateHelper.trickDatetime(DateTime.fromISO(x.lastHealthUpdate))
                              .toUTC()
                              .toFormat(_databaseFormat),
                          },
                        }).then(() => {
                          onClose();
                          fetchMedicalCase(medicalCase.id.toString());
                        });
                      }}
                      defaultValues={{ lastHealthUpdate: medicalCase.lastHealthUpdate || DateTime.now().toISO() }}
                    >
                      <Form.Section title="">
                        <Form.Section.Row>
                          <StaticDatePickerInput name="lastHealthUpdate" maxDate={DateTime.now()} />
                        </Form.Section.Row>
                      </Form.Section>
                      <Form.Section title="">
                        <Form.Section.Row>
                          <BtnPrimary type="submit">{t('save')}</BtnPrimary>
                        </Form.Section.Row>
                      </Form.Section>
                    </ConnectedForm>
                  )}
                </RoutedModal>
                <RoutedModal predicatedHash={newCaseHash} defaultUrl={currentRoute} title={t('newCase')}>
                  {(onClose: () => void) => (
                    <NewCaseForm
                      formId="new-case-form"
                      onSubmit={createNewCase}
                      onClose={onClose}
                      medicalCase={medicalCase}
                    />
                  )}
                </RoutedModal>
                {isAdmin && (
                  <RoutedModal predicatedHash={forceNewCaseHash} defaultUrl={currentRoute} title={t('forceNewCase')}>
                    {(onClose: () => void) => (
                      <NewCaseForm
                        formId="force-new-case-form"
                        onSubmit={forceCreateNewCase}
                        onClose={onClose}
                        medicalCase={medicalCase}
                      />
                    )}
                  </RoutedModal>
                )}
                <ConfirmRoutedModal
                  predicatedHash={syncHash}
                  defaultUrl={currentRoute}
                  title={t('syncMedicalCase')}
                  value="a"
                  onConfirm={async (value, close) => {
                    await syncMedicalCase(medicalCase.id);
                    close();
                  }}
                ></ConfirmRoutedModal>
              </>
            );
          }}
        </RemoteDataContent.Success>
      </RemoteDataContent>
      <SuccessRemoteDataToast
        value={rUpdatedMedicalInformation}
        onClick={() => setRUpdatedMedicalInformation(notAsked())}
      />
      <SuccessRemoteDataToast value={rPushResult} onClick={() => setRPushResult(notAsked())} />
      <SuccessRemoteDataToast value={rSyncResult} onClick={() => setRSyncResult(notAsked())} />
      <SuccessRemoteDataToast value={rSyncPhotos} onClick={() => setRSSyncPhotos(notAsked())} />
      <SuccessRemoteDataToast value={rNewCase} onClick={() => setRNewCase(notAsked())} />
      <SuccessRemoteDataToast value={rNewForcedCase} onClick={() => setRNewForcedCase(notAsked())} />
      <SuccessRemoteDataToast value={rDeleteFile} onClick={() => setDeleteResponse(notAsked())} />
      <SuccessRemoteDataToast value={rSyncOrders} onClick={() => setRSyncOrders(notAsked())} />
      <SuccessRemoteDataToast value={rSyncSubscription} onClick={() => setRSyncSubcription(notAsked())} />
      <SuccessRemoteDataToast value={rOverrideMdiCase} onClick={() => setMdiCase(notAsked())} />
      <SuccessRemoteDataToast value={rSyncMedicalCase} onClick={() => setSyncMedicalCaseResult(notAsked())} />
    </Container>
  );
};

type NewCaseProps = {
  onSubmit: (params?: CreateCasePayload & { id: number }) => Promise<RemoteData<MedicalCaseModel, unknown>>;
  formId: string;
  medicalCase: MedicalCaseModel;
  onClose: () => void;
};

const NewCaseForm = ({ onSubmit, formId, medicalCase, onClose }: NewCaseProps) => {
  const { t } = useTranslation('medical_case');
  const availableSkus = medicalCase.claimedProducts.filter((cp) => !cp.isIdle).map((cp) => cp.sku);

  return (
    <ConnectedForm<Omit<CreateCasePayload, 'chargeable'> & { chargeable: boolean }>
      id={formId}
      onSubmit={(x) => {
        onSubmit({
          id: medicalCase.id,
          skus: availableSkus,
          reasonForEncounter: x.reasonForEncounter,
          sendToCreatedQueue: x.sendToCreatedQueue,
          chargeable: x.chargeable ? 'yes' : 'calculated',
        }).then(() => onClose());
      }}
      defaultValues={{ skus: [], reasonForEncounter: '', sendToCreatedQueue: false }}
    >
      <Form.Section title="">
        <Form.Section.Row>
          <TextField
            required
            label={t('skus')}
            value={availableSkus.length ? availableSkus.join(', ') : t('noActiveSubscriptions')}
            readOnly
            error={!availableSkus.length}
          />
        </Form.Section.Row>
        <Form.Section.Row>
          <TextInput required label={t('reasonForEncounter')} name="reasonForEncounter" />
        </Form.Section.Row>
        <Form.Section.Row>
          <CheckboxInput required label={t('sendToCreatedQueue')} name="sendToCreatedQueue" />
          <CheckboxInput required label={t('isChargeable')} name="chargeable" />
        </Form.Section.Row>
      </Form.Section>
      <Form.Section title="">
        <Form.Section.Row>
          <BtnPrimary disabled={!availableSkus.length} type="submit">
            {t('save')}
          </BtnPrimary>
        </Form.Section.Row>
      </Form.Section>
    </ConnectedForm>
  );
};

const medicalCaseErrorsTooltips = (errors: string[]) => {
  if (!errors.length) return null;

  return (
    <Tooltips.Multiple>
      {errors.map((e) => (
        <Tooltips.Single>
          <span>{e}</span>
        </Tooltips.Single>
      ))}
    </Tooltips.Multiple>
  );
};

const MedicalCaseErrors = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation('medical_case');

  if (!errors.length) return null;

  return (
    <Section>
      <Section.Title>{t('medicalCaseErrors')}</Section.Title>
      <SectionContent>
        <div>
          {errors.map((error) => (
            <div style={{ color: 'red' }} key={error}>
              {error}
            </div>
          ))}
        </div>
      </SectionContent>
    </Section>
  );
};

export default DetailPage;
