export interface OrderShipping {
  orderId: number;
  version: number;
  carrier: Carrier;
  shippingMethod: ShippingMethods;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  pharmacyName: string;
}

export type Carrier = 'USPS';

export type ShippingMethods = UspsShippingMethod;

export type UspsShippingMethod = 'usps_first' | 'usps_priority';

export const getAvailableShippingMethods = (carrier: Carrier): [ShippingMethods?, ShippingMethods[]?] => {
  if (carrier === 'USPS') return ['usps_first', ['usps_first', 'usps_priority']];
  return [];
};
