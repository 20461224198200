import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DateTime } from 'luxon';

type Props = {
  onChange: (date: Date | null) => void;
  value: Date | null;
  maxDate?: DateTime;
  label?: string;
};

export default function StaticDatePickerField({ value, onChange, maxDate, label }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StaticDatePicker
        onChange={onChange}
        localeText={{ toolbarTitle: label }}
        value={value}
        // Because MUI props are weird
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        maxDate={maxDate as any}
      />
    </LocalizationProvider>
  );
}
