import { Tabs, Tab, SxProps, Theme, AppBar, Toolbar, Box, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '.';

type Route =
  | {
      label: string;
      href: string;
      hidden?: boolean;
      subNavigation?: JSX.Element;
    }
  | { label: string; menu: { label: string; href: string }[] };

const LinkTab = ({
  label,
  href,
  sx,
  hidden,
}: {
  sx?: SxProps<Theme> | undefined;
  label: string;
  href: string;
  hidden?: boolean;
}) => <Tab sx={{ ...sx, display: hidden ? 'none' : undefined }} component={Link} label={label} to={href} />;

interface Props {
  currentRoute: string;
  routes: Route[];
  logoutRoute: { label: string; href: string };
}

const TabNavbar = ({ route }: { route: Route }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  if (!('menu' in route))
    return <LinkTab key={route.href} label={route.label} href={route.href} hidden={route.hidden} />;
  return (
    <>
      <Tab
        label={
          <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span>{route.label}</span> <Icons.ChevronDown />
          </Box>
        }
        onClick={handleOpenUserMenu}
      />
      <Menu anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
        {route.menu.map((m) => (
          <MenuItem key={m.label}>
            <LinkTab label={m.label} href={m.href} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const Navbar = ({ currentRoute, routes, logoutRoute }: Props) => {
  const currentRouteIndex = useMemo(
    () =>
      routes.findIndex((x) => {
        if ('href' in x) return x.href === currentRoute;

        return x.menu.some((s) => s.href === currentRoute);
      }),
    [currentRoute, routes],
  );
  const subNavigation = (() => {
    const t = routes[currentRouteIndex];
    if (!t) return undefined;
    if ('subNavigation' in t) return t.subNavigation;
    return undefined;
  })();

  return (
    <Box sx={{ height: subNavigation ? '112px' : '64px' }}>
      <AppBar position="fixed" color="primary" sx={{ height: '64px' }}>
        <Toolbar>
          <Tabs
            value={currentRouteIndex}
            aria-label="nav tabs example"
            variant="scrollable"
            indicatorColor="secondary"
            sx={{ flexGrow: 1 }}
          >
            {routes.map((route) => (
              <TabNavbar route={route} key={route.label} />
            ))}
          </Tabs>
          <LinkTab label={logoutRoute.label} href={logoutRoute.href} />
        </Toolbar>
      </AppBar>
      {subNavigation && (
        <AppBar
          position="fixed"
          color="secondary"
          sx={{ top: '64px', height: '48px', paddingLeft: '10rem', color: '#305769' }}
        >
          {subNavigation}
        </AppBar>
      )}
    </Box>
  );
};

export default Navbar;
