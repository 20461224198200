import { useTranslation } from 'react-i18next';
import { Icons, Title, Link, Tooltips } from '../common/ui-component';
import { DateFormatter } from '../common/ui-component/data-table';
import PendingOrdersService from './pendingOrder.service';
import { PendingOrderModel, PendingOrderQuestionDto, PendingOrderQuestionsModel } from '../models';
import DataTable from '../common/ui-component/data-table/DataTablePro';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

const getActions = ({ id }: GridRowParams) => [
  <Link to={`/pending-orders/${id}`}>
    <Icons.Edit />
  </Link>,
];

const getColumns = (t: (key: string) => string): GridColDef[] => [
  {
    field: 'id',
    headerName: t('id'),
    flex: 1,
  },
  {
    field: 'uniqueId',
    headerName: t('uniqueId'),
    flex: 1,
  },
  {
    field: 'email',
    headerName: t('email'),
    flex: 1,
  },
  {
    field: 'dateOfBirth',
    headerName: t('dob'),
    flex: 1,
  },
  {
    field: 'gender',
    headerName: t('gender'),
    flex: 1,
  },
  {
    field: 'questions',
    headerName: t('questions'),
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltips value={<pre>{JSON.stringify(params.value, undefined, 2)}</pre>}>
          <pre style={{ overflow: 'auto' }}>hover me</pre>
        </Tooltips>
      );
    },
  },
  {
    field: 'created',
    headerName: t('created'),
    headerAlign: 'center',
    flex: 1,
    valueFormatter: DateFormatter.normalize,
  },
  {
    field: 'createdWithTime',
    headerName: t('createdWithTime'),
    headerAlign: 'center',
    flex: 1,
    valueFormatter: DateFormatter.withTime,
    filterable: false,
    sortable: false,
  },
  {
    field: 'updated',
    headerName: t('updated'),
    headerAlign: 'center',
    flex: 1,
    valueFormatter: DateFormatter.normalize,
  },
  {
    field: 'updatedWithTime',
    headerName: t('updatedWithTime'),
    headerAlign: 'center',
    flex: 1,
    valueFormatter: DateFormatter.withTime,
    filterable: false,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    getActions,
  },
];

function PendingOrders() {
  const { t } = useTranslation('pending_order');
  const [getPendingOrders, pendingOrders] = PendingOrdersService.useGetPendingOrders();

  const mapToHumanReadable = (questions: PendingOrderQuestionsModel): string => {
    return questions
      .map(
        (q: PendingOrderQuestionDto) =>
          `${q.longName} | Answer: ${q.value}` +
          (q.description ? ` | Description: ${q.description.replace(/[\n\r]/g, '')}` : ''),
      )
      .join('\n');
  };

  return (
    <>
      <Title>{t('pendingOrders')}</Title>

      <DataTable
        id="pending-order-table"
        maxExportPageSize={500}
        cols={getColumns(t)}
        fetcher={getPendingOrders}
        datas={pendingOrders}
        toRow={(pOrder: PendingOrderModel) => ({
          ...pOrder,
          createdWithTime: pOrder.created,
          updatedWithTime: pOrder.updated,
        })}
        initialColumnVisibilityModel={{ createdWithTime: false, updatedWithTime: false }}
        toCsvRow={(pOrder: PendingOrderModel) => ({
          ...pOrder,
          questions: mapToHumanReadable(pOrder.questions),
        })}
        checkboxSelection
      />
    </>
  );
}

export default PendingOrders;
