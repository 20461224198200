import { AccessTokenProvider } from './authentication';
import Router from './Router';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  // eslint-disable-next-line max-len
  '7e5874a3518f30ef3775a05364acddbdTz0xMDY0MjgsRT0xNzY5MTI2Mzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

function App() {
  return (
    <AccessTokenProvider>
      <Router />
    </AccessTokenProvider>
  );
}

export default App;
