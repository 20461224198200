import { TFunction, useTranslation } from 'react-i18next';
import { DataTablePro as DataTable, Icons, Title } from '../common/ui-component';
import { MarketingProfileModel } from '../models';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CellRender, DateFormatter } from '../common/ui-component/data-table';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { CUSTOMERS_ROUTE } from '../Router';
import MarketingPlaformService from './marketing-platform.service';

type MarketingPlatformColumn = {
  field:
    | keyof MarketingProfileModel
    | 'healthUpdateDueLapsedAddedWithTime'
    | 'healthUpdateDueLapsedRemovedWithTime'
    | 'healthUpdateDueListAddedWithTime'
    | 'healthUpdateDueListRemovedWithTime'
    | 'healthUpdateDueDateWithTime'
    | 'lastHealthUpdateWithTime'
    | 'createdWithTime';
} & GridColDef;

const getColumns = (t: TFunction<'marketing_platform', undefined>): GridColDef[] => {
  const cols: MarketingPlatformColumn[] = [
    {
      field: 'customerId',
      headerName: t('customerId'),
      renderCell: CellRender.Link(CUSTOMERS_ROUTE),
    },
    { field: 'externalId', headerName: t('externalId') },
    { field: 'firstName', headerName: t('firstName') },
    { field: 'lastName', headerName: t('lastName') },
    { field: 'uploadedHairloss', headerName: t('uploadedHairloss') },
    { field: 'uploadedId', headerName: t('uploadedId') },
    {
      field: 'isInactive',
      headerName: t('isActive'),
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return <Icons.Cancel />;
        }
        return <Icons.Checked />;
      },
    },
    {
      field: 'healthUpdateDueLapsedAdded',
      headerName: t('healthUpdateDueLapsedAdded'),
      valueFormatter: DateFormatter.normalize,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'healthUpdateDueLapsedAddedWithTime',
      headerName: t('healthUpdateDueLapsedAddedWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'healthUpdateDueLapsedRemoved',
      headerName: t('healthUpdateDueLapsedRemoved'),
      valueFormatter: DateFormatter.normalize,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'healthUpdateDueLapsedRemovedWithTime',
      headerName: t('healthUpdateDueLapsedRemovedWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'healthUpdateDueListAdded',
      headerName: t('healthUpdateDueListAdded'),
      valueFormatter: DateFormatter.normalize,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'healthUpdateDueListAddedWithTime',
      headerName: t('healthUpdateDueListAddedWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'healthUpdateDueListRemoved',
      headerName: t('healthUpdateDueListRemoved'),
      valueFormatter: DateFormatter.normalize,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'healthUpdateDueListRemovedWithTime',
      headerName: t('healthUpdateDueListRemovedWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'healthUpdateDueDate',
      headerName: t('healthUpdateDueDate'),
      valueFormatter: DateFormatter.normalize,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'healthUpdateDueDateWithTime',
      headerName: t('healthUpdateDueDateWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'lastHealthUpdate',
      headerName: t('lastHealthUpdate'),
      valueFormatter: DateFormatter.normalize,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'lastHealthUpdateWithTime',
      headerName: t('lastHealthUpdateWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'created',
      headerName: t('created'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'createdWithTime',
      headerName: t('createdWithTime'),
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
  ];

  return cols;
};

function ListPage() {
  const { t } = useTranslation('marketing_platform');
  const [search, rResult] = MarketingPlaformService.useSearch();

  return (
    <>
      <Title>{t('marketingPlatform')}</Title>

      <DataTable
        id="marketing-plateform-table"
        cols={getColumns(t)}
        fetcher={search}
        datas={rResult}
        getRowId={(x) => x.customerId}
        maxExportPageSize={2000}
        toRow={(marketingPlatform: MarketingProfileModel) => ({
          ...marketingPlatform,
          healthUpdateDueLapsedAddedWithTime: marketingPlatform.healthUpdateDueLapsedAdded,
          healthUpdateDueLapsedRemovedWithTime: marketingPlatform.healthUpdateDueLapsedRemoved,
          healthUpdateDueListAddedWithTime: marketingPlatform.healthUpdateDueListAdded,
          healthUpdateDueListRemovedWithTime: marketingPlatform.healthUpdateDueListRemoved,
          healthUpdateDueDateWithTime: marketingPlatform.healthUpdateDueDate,
          lastHealthUpdateWithTime: marketingPlatform.lastHealthUpdate,
        })}
        initialColumnVisibilityModel={{
          healthUpdateDueLapsedAddedWithTime: false,
          healthUpdateDueLapsedRemovedWithTime: false,
          healthUpdateDueListAddedWithTime: false,
          healthUpdateDueListRemovedWithTime: false,
          healthUpdateDueDateWithTime: false,
          lastHealthUpdateWithTime: false,
        }}
      />
    </>
  );
}

export default ListPage;
