import React from 'react';
import { GridColDef, GridLogicOperator, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { TFunction, useTranslation } from 'react-i18next';
import { BtnDanger, DataTablePro as DataTable, Icons, Link, Section, Title, Tooltips } from '../../common/ui-component';
import { CellRender, DateFormatter } from '../../common/ui-component/data-table';
import FilterOperator from '../../common/ui-component/data-table/filters/filter-operator-pro';
import MedicalCaseService from '../medical-case.service';
import MedicalCaseValidator from '../medical-case.validator';
import {
  MedicalCaseModel,
  MedicalCasePrescriptionModel,
  MedicalCaseRxCompoundModel,
  MedicalCaseRxMedicationModel,
} from '../../models';
import { booleanIntegerValueOptions } from '../../common/ui-component/data-table/filters/backoffice-query';
import { DateTime } from 'luxon';
import { _databaseFormat } from '../../utils/date/helper';
import RemoteDataContent from '../../common/ui-component/layouts/RemoteDataContent.v2';
import { Alert, Snackbar } from '@mui/material';
import { notAsked } from '../../utils/remote-data';
import { MEDICAL_CASE_ANNUAL_HEALTH_UPDATE_PATH, MEDICAL_CASE_ROUTE } from '../../Router';
import { ConfirmRoutedModal } from '../../common/ui-component/modals';

const getActions =
  (triggerHealthUpdate: (id: string) => void) =>
  ({ id }: GridRowParams) =>
    [
      <BtnDanger onClick={() => triggerHealthUpdate(id.toString())}>
        <Icons.Replay />
      </BtnDanger>,
    ];

type MedicalCaseColumn = {
  field:
    | keyof MedicalCaseModel
    | 'actions'
    | 'virtualActive'
    | 'lastHealthUpdateWithTime'
    | 'createdAtWithTime'
    | 'healthUpdateDueDateWithTime'
    | 'lastEncounteredWithTime'
    | 'lastPrescriptionSignedWithTime';
} & GridColDef;

const getColumns = (
  t: TFunction<'medical_case', undefined>,
  triggerHealthUpdate: (id: string) => void,
): GridColDef[] => {
  const col: MedicalCaseColumn[] = [
    { field: 'id', headerName: t('id'), renderCell: CellRender.Link(MEDICAL_CASE_ROUTE) },
    {
      field: 'email',
      headerName: t('email'),
    },
    {
      field: 'firstName',
      headerName: t('firstName'),
    },
    {
      field: 'lastName',
      headerName: t('lastName'),
    },
    {
      field: 'phoneNumber',
      headerName: t('phoneNumber'),
    },
    {
      field: 'patientId',
      headerName: t('hasPatientId'),
      renderHeader: () => (
        <Tooltips value={t('patientId')}>
          <span>{t('hasPatientId')}</span>
        </Tooltips>
      ),

      filterOperators: FilterOperator.getGridNullOperators(),
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.patientId)
          return (
            <Tooltips value={params.value}>
              <span>
                <Icons.Checked />
              </span>
            </Tooltips>
          );

        return (
          <Tooltips
            value={
              <div>
                {MedicalCaseValidator.getPatientIdErrors(params.row, t).map((error) => (
                  <div key={error}>{error}</div>
                ))}
              </div>
            }
          >
            <span>
              <Icons.Cancel />
            </span>
          </Tooltips>
        );
      },
    },

    {
      field: 'prescriptions',
      headerName: t('hasPrescriptions'),
      renderHeader: () => (
        <Tooltips value={t('prescriptions')}>
          <span>{t('hasPrescriptions')}</span>
        </Tooltips>
      ),

      filterOperators: FilterOperator.getGridArrayHasValueOperators(),
      renderCell: (params: GridRenderCellParams) => {
        if (
          (params.row.claimedProducts || []).length &&
          (params.row.claimedProducts || []).every((cp: MedicalCaseRxMedicationModel | MedicalCaseRxCompoundModel) =>
            (params.value || []).some((p: MedicalCasePrescriptionModel) => cp.sku === p.medication.sku),
          )
        )
          return (
            <Tooltips value={params.value.map((x: MedicalCasePrescriptionModel) => x.medication.sku).join(', ')}>
              <span>
                <Icons.Checked />
              </span>
            </Tooltips>
          );

        return <Icons.Cancel />;
      },
    },
    {
      field: 'lastPrescriptionSigned',
      headerName: t('lastPrescriptionSigned'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'lastPrescriptionSignedWithTime',
      headerName: t('lastPrescriptionSignedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'lastEncountered',
      headerName: t('lastEncountered'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'lastEncounteredWithTime',
      headerName: t('lastEncounteredWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'healthUpdateDueDate',
      headerName: t('healthUpdateDueDate'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'healthUpdateDueDateWithTime',
      headerName: t('healthUpdateDueDateWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'lastHealthUpdate',
      headerName: t('lastHufSubmission'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'lastHealthUpdateWithTime',
      headerName: t('lastHufSubmissionWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'virtualActive',
      headerName: t('active'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.active) return <Icons.Checked />;

        return <Icons.Cancel />;
      },
    },
    {
      field: 'createdAt',
      headerName: t('created'),
      headerAlign: 'center',
      filterOperators: FilterOperator.getGridDateOperators(),

      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'createdAtWithTime',
      headerName: t('createdWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: getActions(triggerHealthUpdate),
    },
  ];

  return col;
};

function ListPage() {
  const { t } = useTranslation('medical_case');
  const [fetchMedicalCases, rMedicalCases] = MedicalCaseService.useGetMedicalCases();
  const [triggerHealthUpdate, rHealth, setRHealth] = MedicalCaseService.useTriggerHealthUpdate();
  const [triggerHealthUpdates, triggerResult, setTriggerReuslt] = MedicalCaseService.useTriggerHealthUpdates();
  const currentRoute = `${MEDICAL_CASE_ROUTE}/${MEDICAL_CASE_ANNUAL_HEALTH_UPDATE_PATH}`;

  const hashCreateEncounterForHUDD = '#HUDD';

  return (
    <>
      <Title>{t('medicalCases')}</Title>
      <Section
        title=""
        actions={
          <Link to={`${currentRoute}/${hashCreateEncounterForHUDD}`}>
            <BtnDanger>
              <>
                TRIGGER CREATE ENCOUNTER FOR HUDD FOR TODAY
                <Icons.Bomb />
              </>
            </BtnDanger>
          </Link>
        }
      >
        <></>
      </Section>
      <DataTable
        id="medical-case-table"
        cols={getColumns(t, triggerHealthUpdate)}
        fetcher={fetchMedicalCases}
        defaultFilters={{
          logicOperator: GridLogicOperator.And,
          items: [
            {
              field: 'virtualActive',
              operator: 'equals',
              value: 1,
            },
            {
              field: 'healthUpdateDueDate',
              operator: 'on',
              value: DateTime.now().toFormat(_databaseFormat),
            },
            {
              field: 'lastEncountered',
              operator: 'onOrBefore',
              value: DateTime.fromISO('2024-01-01T00:00:00').toFormat(_databaseFormat),
            },
          ],
        }}
        datas={rMedicalCases}
        toRow={(medicalCase: MedicalCaseModel) => ({
          ...medicalCase,
          lastPrescriptionSignedWithTime: medicalCase.lastPrescriptionSigned,
          lastEncounteredWithTime: medicalCase.lastEncountered,
          healthUpdateDueDateWithTime: medicalCase.healthUpdateDueDate,
          lastHealthUpdateWithTime: medicalCase.lastHealthUpdate,
          createdAtWithTime: medicalCase.createdAt,
          proveVerified: !medicalCase.driverLicenseRequired,
          virtualActive: medicalCase.active,
        })}
        initialColumnVisibilityModel={{
          createdAtWithTime: false,
          healthUpdateDueDateWithTime: false,
          lastEncounteredWithTime: false,
          lastPrescriptionSignedWithTime: false,
          lastHealthUpdateWithTime: false,
        }}
        toCsvRow={(medicalCase: MedicalCaseModel) => ({
          ...medicalCase,
          prescriptions:
            (medicalCase.claimedProducts || []).length &&
            (medicalCase.claimedProducts || []).every((cp: MedicalCaseRxMedicationModel | MedicalCaseRxCompoundModel) =>
              (medicalCase.prescriptions || []).some((p: MedicalCasePrescriptionModel) => cp.sku === p.medication.sku),
            ),
          claimedProducts: JSON.stringify(medicalCase.claimedProducts),
          photos: JSON.stringify(medicalCase.photos),
          medicalInformation: JSON.stringify(medicalCase.medicalInformation),
          proveVerified: !medicalCase.driverLicenseRequired,
          virtualActive: medicalCase.active,
        })}
      />
      <RemoteDataContent value={rHealth}>
        <RemoteDataContent.Success>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setRHealth(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.Failure>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="error"
                onClick={() => {
                  setRHealth(notAsked());
                }}
              >
                {'Failed -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Failure>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
      <ConfirmRoutedModal
        predicatedHash={hashCreateEncounterForHUDD}
        defaultUrl={currentRoute}
        title={'Are you sure you want trigger case for all health update due today  ?'}
        value={'a'}
        onConfirm={(_, close) => {
          triggerHealthUpdates();
          close();
        }}
      ></ConfirmRoutedModal>
      <RemoteDataContent value={triggerResult}>
        <RemoteDataContent.Success>
          {(_) => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setTriggerReuslt(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
    </>
  );
}

export default ListPage;
