import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { TFunction, useTranslation } from 'react-i18next';
import ProductService from './product.service';
import { BtnDanger, DataTablePro as DataTable, Icons, Title } from '../common/ui-component';
import { Product, ProductCategory, ProductMedication } from './product.entity';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { Alert, Snackbar } from '@mui/material';
import { notAsked } from '../utils/remote-data';

const getActions =
  (syncFromWoo: (id: string) => void) =>
  ({ row }: GridRowParams) =>
    [
      <BtnDanger onClick={() => syncFromWoo(row.wooCommerceId.toString())}>
        <Icons.Sync />
      </BtnDanger>,
    ];

const getColumns = (t: TFunction<'product', undefined>, syncFromWoo: (id: string) => void): GridColDef[] => [
  {
    field: 'sku',
    headerName: t('sku'),
  },
  {
    field: 'wooCommerceId',
    headerName: t('wooCommerceId'),
  },
  {
    field: 'name',
    headerName: t('name'),
  },
  {
    field: 'distributor',
    headerName: t('pharmacy'),
  },
  {
    field: 'mdiTitle',
    headerName: t('mdiTitle'),
    renderCell: (params: GridRenderCellParams<Product>) => {
      const medications = params.row?.medications;
      if (!Array.isArray(medications)) return '';
      return ((medications as ProductMedication[]) || [])
        .map((m) => m.title)
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    field: 'mdiId',
    headerName: t('mdiId'),
    renderCell: (params: GridRenderCellParams<Product>) => {
      const medications = params.row?.medications;
      if (!Array.isArray(medications)) return '';
      return ((medications as ProductMedication[]) || [])
        .map((m) => ('partner_medication_id' in m ? m.partner_medication_id : m.partner_compound_id))
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    field: 'categories',
    headerName: t('category'),
    renderCell: (params: GridRenderCellParams<ProductCategory[]>) => {
      if (!params.value?.length) return '';
      return params.value.some((c: ProductCategory) => c.name === 'Rx') ? 'Rx' : 'OTC';
    },
  },
  {
    field: 'hasImage',
    headerName: t('hasImage'),
  },
  {
    field: 'created',
    headerName: t('created'),
  },
  {
    field: 'createdWithTime',
    headerName: t('createdWithTime'),
  },
  {
    field: 'actions',
    type: 'actions',
    getActions: getActions(syncFromWoo),
  },
];

const Products = () => {
  const { t } = useTranslation('product');
  const [fetchProducts, rProducts] = ProductService.useSearchProducts();
  const [syncFromWoo, rSync, setRSync] = ProductService.useSyncFromWoo();

  return (
    <>
      <Title>{t('products')}</Title>

      <DataTable
        id="product-table"
        cols={getColumns(t, syncFromWoo)}
        fetcher={fetchProducts}
        datas={rProducts}
        toRow={(pProduct: Product) => ({
          ...pProduct,
          createdWithTime: pProduct.created,
          hasImage: !!pProduct.images.length,
        })}
        toCsvRow={(pProduct: Product) => ({
          ...pProduct,
          categories: (pProduct.categories || []).map((c) => c.name).join(', '),
        })}
        initialColumnVisibilityModel={{ createdWithTime: false }}
        checkboxSelection
      />
      <RemoteDataContent value={rSync}>
        <RemoteDataContent.Success>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setRSync(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
    </>
  );
};

export default Products;
