import { useTranslation, TFunction } from 'react-i18next';
import { BtnDanger, Icons, Title } from '../common/ui-component';
import RenewalReminderService from './renewal-reminder.service';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { CellRender, DateFormatter } from '../common/ui-component/data-table';
import { CUSTOMERS_ROUTE, SUBSCRIPTIONS_ROUTE } from '../Router';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import DataTable from '../common/ui-component/data-table/DataTablePro';
import { RenewalReminder } from '../models';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { Alert, Snackbar } from '@mui/material';
import { notAsked } from '../utils/remote-data';

const getActions =
  (attachPhotos: (id: string) => void) =>
  ({ id }: GridRowParams) =>
    [
      <BtnDanger onClick={() => attachPhotos(id.toString())}>
        <Icons.Send />
      </BtnDanger>,
    ];

const getColumns = (t: TFunction<'renewal_reminder', undefined>, remind: (id: string) => void): GridColDef[] => {
  return [
    { field: 'id', headerName: t('id') },
    {
      field: 'customerId',
      headerName: t('customerId'),
      renderCell: CellRender.Link(CUSTOMERS_ROUTE),
    },
    {
      field: 'subscriptionId',
      headerName: t('subscriptionId'),
      renderCell: CellRender.Link(SUBSCRIPTIONS_ROUTE),
    },
    {
      field: 'sent',
      headerName: t('sent'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'sentWithTime',
      headerName: t('sentWithTime'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'created',
      headerName: t('created'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'createdWithTime',
      headerName: t('createdWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'updated',
      headerName: t('updated'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },

    {
      field: 'updatedWithTime',
      headerName: t('updatedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: getActions(remind),
    },
  ];
};

export default function ListPage() {
  const { t } = useTranslation('renewal_reminder');
  const [search, rResult] = RenewalReminderService.useSearch();
  const [remind, rRemind, setRRemind] = RenewalReminderService.useReminder();
  return (
    <>
      <Title>{t('renewalReminder')}</Title>
      <DataTable
        id="renewal-reminder-table"
        cols={getColumns(t, remind)}
        fetcher={search}
        datas={rResult}
        maxExportPageSize={2000}
        defaultSort={{ order: 'asc', orderBy: 'sent' }}
        toRow={(renewalReminder: RenewalReminder) => ({
          ...renewalReminder,
          updatedWithTime: renewalReminder.updated,
          createdWithTime: renewalReminder.created,
        })}
        initialColumnVisibilityModel={{
          updatedWithTime: false,
          createdWithTime: false,
        }}
      />
      <RemoteDataContent value={rRemind}>
        <RemoteDataContent.Success>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setRRemind(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
    </>
  );
}
