import { TFunction, useTranslation } from 'react-i18next';
import { DataTablePro as DataTable, Icons, Title } from '../common/ui-component';
import { HairYardModel } from '../models';
import { GridColDef, GridLogicOperator, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { CellRender, DateFormatter } from '../common/ui-component/data-table';
import HairYardService from './hair-yard.service';
import { BtnDanger } from '../common/ui-component/buttons';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { Alert, Snackbar } from '@mui/material';
import { notAsked } from '../utils/remote-data';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { CUSTOMERS_ROUTE, MEDICAL_CASE_ROUTE } from '../Router';
import { _databaseFormat } from '../utils/date/helper';
import { DateTime } from 'luxon';

const getActions =
  (attachPhotos: (id: string) => void) =>
  ({ id }: GridRowParams) =>
    [
      <BtnDanger onClick={() => attachPhotos(id.toString())}>
        <Icons.Replay />
      </BtnDanger>,
    ];

const getColumns = (t: TFunction<'hair_yard', undefined>, attachPhotos: (id: string) => void): GridColDef[] => {
  return [
    { field: 'id', headerName: t('id') },
    {
      field: 'customerId',
      headerName: t('customerId'),
      renderCell: CellRender.Link(CUSTOMERS_ROUTE),
    },
    {
      field: 'medicalCaseId',
      headerName: t('medicalCaseId'),
      renderCell: CellRender.Link(MEDICAL_CASE_ROUTE),
    },

    { field: 'gender', headerName: t('gender'), filterable: false },
    { field: 'driverLicenseRequired', headerName: t('driverLicenseRequired'), filterable: false },
    {
      field: 'videoRequired',
      headerName: t('videoRequired'),
      filterOperators: FilterOperator.getGridIsBooleanOperator(),
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
    },
    { field: 'hairLossLocations', headerName: t('hairLossLocations'), filterable: false },
    {
      field: 'driverLicenseUploaded',
      headerName: t('driverLicenseUploaded'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'driverLicenseUploadedWithTime',
      headerName: t('driverLicenseUploadedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'hairPhotoUploaded',
      headerName: t('hairPhotoUploaded'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'hairPhotoUploadedWithTime',
      headerName: t('hairPhotoUploadedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'photoAttached',
      headerName: t('photoAttached'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'photoAttachedWithTime',
      headerName: t('photoAttachedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'removed',
      headerName: t('removed'),
      valueFormatter: DateFormatter.dateOfBirth,
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
    },
    {
      field: 'removedWithTime',
      headerName: t('removedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'medicalCaseCreated',
      headerName: t('medicalCaseCreated'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'medicalCaseCreatedWithTime',
      headerName: t('medicalCaseCreatedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'created',
      headerName: t('created'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'createdWithTime',
      headerName: t('createdWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'updated',
      headerName: t('updated'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'updatedWithTime',
      headerName: t('updatedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: getActions(attachPhotos),
    },
  ];
};

function ListPage() {
  const { t } = useTranslation('hair_yard');
  const [search, rResult] = HairYardService.useSearch();
  const [attachPhotos, rAttachs, setRAttachs] = HairYardService.useAttachPhotos();

  return (
    <>
      <Title>{t('hairYard')}</Title>

      <DataTable
        id="hair-yard-table"
        cols={getColumns(t, attachPhotos)}
        fetcher={search}
        datas={rResult}
        maxExportPageSize={2000}
        defaultSort={{ order: 'asc', orderBy: 'medicalCaseCreated' }}
        defaultFilters={{
          logicOperator: GridLogicOperator.And,
          items: [
            {
              id: 'healthUpdateDueDate-1',
              field: 'removed',
              operator: 'has',
              value: 'false',
            },
            {
              id: 'medicalCaseCreated-1',
              field: 'medicalCaseCreated',
              operator: 'onOrBefore',
              value: DateTime.now().minus({ days: 3 }).toUTC().toFormat(_databaseFormat),
            },
          ],
        }}
        toRow={(hairYard: HairYardModel) => ({
          ...hairYard,
          updatedWithTime: hairYard.updated,
          createdWithTime: hairYard.created,
          medicalCaseCreatedWithTime: hairYard.medicalCaseCreated,
          removedWithTime: hairYard.removed,
          photoAttachedWithTime: hairYard.photoAttached,
          hairPhotoUploadedWithTime: hairYard.hairPhotoUploaded,
          driverLicenseUploadedWithTime: hairYard.driverLicenseUploaded,
        })}
        initialColumnVisibilityModel={{
          updatedWithTime: false,
          createdWithTime: false,
          medicalCaseCreatedWithTime: false,
          removedWithTime: false,
          photoAttachedWithTime: false,
          hairPhotoUploadedWithTime: false,
          driverLicenseUploadedWithTime: false,
        }}
        toCsvRow={(hairYard: HairYardModel) => ({
          ...hairYard,
          hairLossLocations: hairYard.hairLossLocations?.join(', '),
        })}
      />
      <RemoteDataContent value={rAttachs}>
        <RemoteDataContent.Success>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setRAttachs(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
    </>
  );
}

export default ListPage;
