import { TFunction, useTranslation } from 'react-i18next';
import { DataTablePro as DataTable, Icons, Link, Title } from '../common/ui-component';
import HealthUpdateTrackerService from './health-update.tracker.service';
import { HealthUpdateTrackerModel } from '../models';
import { GridColDef, GridLogicOperator, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { CellRender, DateFormatter } from '../common/ui-component/data-table';
import { DateTime } from 'luxon';
import { _databaseFormat } from '../utils/date/helper';
import { CUSTOMERS_ROUTE, HEALTH_UPDATE_TRACKER_ROUTE, MEDICAL_CASE_ROUTE } from '../Router';

const getActions = ({ id }: GridRowParams) => [
  <Link to={`${HEALTH_UPDATE_TRACKER_ROUTE}/${id}`}>
    <Icons.Edit />
  </Link>,
];

const getConvertedOptions = (t: TFunction<'health_update_tracker', undefined>) => [
  { value: 'completed', label: t('completed') },
  { value: 'incompleted', label: t('incompleted') },
  { value: 'cancelled', label: t('cancelled') },
];

const getColumns = (t: TFunction<'health_update_tracker', undefined>): GridColDef[] => {
  return [
    {
      field: 'customerId',
      headerName: t('customerId'),
      renderCell: CellRender.Link(CUSTOMERS_ROUTE),
    },
    {
      field: 'medicalCaseId',
      headerName: t('medicalCaseId'),
      renderCell: CellRender.Link(MEDICAL_CASE_ROUTE),
    },
    {
      field: 'email',
      headerName: t('email'),
    },
    {
      field: 'healthUpdateFilled',
      headerName: t('healthUpdateFilled'),
      filterOperators: FilterOperator.getGridNullOperators(),
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? <Icons.Checked /> : <Icons.Cancel />;
      },
    },
    {
      field: 'isEncounterCreated',
      headerName: t('inMdi'),
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? <Icons.Checked /> : <Icons.Cancel />;
      },
    },
    {
      field: 'hasEnteredCrm',
      headerName: t('hasEnteredCrm'),
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? <Icons.Checked /> : <Icons.Cancel />;
      },
    },
    {
      field: 'crmEntered',
      headerName: t('crmEntered'),
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'crmEnteredWithTime',
      headerName: t('crmEnteredWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'subscriptionStatus',
      headerName: t('subscriptionStatus'),
    },
    {
      field: 'firstAssignedMD',
      headerName: t('firstAssignedMD'),
    },
    {
      field: 'crmConverted',
      headerName: t('crmConverted'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: getConvertedOptions(t),
    },

    {
      field: 'mdiConverted',
      headerName: t('mdiConverted'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: getConvertedOptions(t),
    },
    {
      field: 'fallbackMdConverted',
      headerName: t('fallbackMdConverted'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: getConvertedOptions(t),
    },
    {
      field: 'prescribingMD',
      headerName: t('prescribingMD'),
    },
    {
      field: 'prescriptionSigned',
      headerName: t('prescriptionSigned'),
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'prescriptionSignedWithTime',
      headerName: t('prescriptionSignedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'encounterCreated',
      headerName: t('encounterCreated'),
      filterOperators: [...FilterOperator.getGridDateOperators(), ...FilterOperator.getGridNullOperators()],
      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'encounterCreatedWithTime',
      headerName: t('encounterCreatedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'fallbackMdAssigned',
      headerName: t('fallbackMdAssigned'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'fallbackMdAssignedWithTime',
      headerName: t('fallbackMdAssignedWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'healthUpdateDueDate',
      headerName: t('healthUpdateDueDate'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'healthUpdateDueDateWithTime',
      headerName: t('healthUpdateDueDateWithTime'),
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions,
    },
  ];
};

function ListPage() {
  const { t } = useTranslation('health_update_tracker');
  const [fetchHealthUpdateTrackers, rHealthUpdateTrackers] = HealthUpdateTrackerService.useGetHealthUpdateTrackers();

  return (
    <>
      <Title>{t('healthUpdateTracker')}</Title>

      <DataTable
        id="health-update-tracker-table"
        cols={getColumns(t)}
        fetcher={fetchHealthUpdateTrackers}
        datas={rHealthUpdateTrackers}
        maxExportPageSize={2000}
        toRow={(healthUpdateTrackerModel: HealthUpdateTrackerModel) => ({
          ...healthUpdateTrackerModel,
          isEncounterCreated: !!healthUpdateTrackerModel.encounterCreated,
          hasEnteredCrm: !!healthUpdateTrackerModel.crmEntered,
          crmEnteredWithTime: healthUpdateTrackerModel.crmEntered,
          prescriptionSignedWithTime: healthUpdateTrackerModel.prescriptionSigned,
          encounterCreatedWithTime: healthUpdateTrackerModel.encounterCreated,
          fallbackMdAssignedWithTime: healthUpdateTrackerModel.fallbackMdAssigned,
          healthUpdateDueDateWithTime: healthUpdateTrackerModel.healthUpdateDueDate,
        })}
        initialColumnVisibilityModel={{
          crmEnteredWithTime: false,
          prescriptionSignedWithTime: false,
          encounterCreatedWithTime: false,
          fallbackMdAssignedWithTime: false,
          healthUpdateDueDateWithTime: false,
        }}
        defaultFilters={{
          logicOperator: GridLogicOperator.And,
          items: [
            {
              id: 'healthUpdateDueDate-1',
              field: 'healthUpdateDueDate',
              operator: 'on',
              value: DateTime.now().toUTC().toFormat(_databaseFormat),
            },
            {
              id: 'subscriptionStatus-1',
              field: 'subscriptionStatus',
              operator: 'equals',
              value: 'active',
            },
            {
              id: 'encounterCreated-1',
              field: 'encounterCreated',
              operator: 'has',
              value: 'false',
            },
          ],
        }}
      />
    </>
  );
}

export default ListPage;
