import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { fold, loading, map, notAsked, withDefault } from '../utils/remote-data';
import {
  BtnPrimary,
  errorHandler,
  Form,
  Header,
  Icons,
  Info,
  Link,
  Loader,
  Section,
  SectionContent,
  SuccessRemoteDataToast,
} from '../common/ui-component';
import CustomerService from './customer.service';
import { ConversionCustomerModel, CustomerModel, MedicalCaseModel } from '../models';
import { DateHelper } from '../utils/date';
import { result } from '../utils/result';
import MedicalCaseService from '../medical-case/medical-case.service';
import { CUSTOMERS_ROUTE, MEDICAL_CASE_ROUTE } from '../Router';
import SubscriptionList from '../subscriptions/Subscriptions';
import SubscriptionsService from '../subscriptions/subscriptions.service';
import { ConnectedForm, DatePickerInput, DropdownInput, TextInput } from '../common/form-controller';
import FormAddress from '../common/address/FormAddress';
import { Box, Button, Tooltip } from '@mui/material';
import EmailInput from '../common/form-controller/EmailInput';
import { DateTime } from 'luxon';
import { _databaseFormat } from '../utils/date/helper';
import Address from './Address';
import SubFormSection from '../common/ui-component/layouts/form/SubFormSection';
import { Btn } from '../common/ui-component/buttons';
import { ConfirmRoutedModal } from '../common/ui-component/modals';
import CustomerAnalyticsService from './customer.analytics.service';

const genderFromNumber = (
  t: TFunction<'common', undefined>,
  num: number,
): { displayText: string; value: number; id: number } => {
  switch (num) {
    case 1:
      return { displayText: t('male'), value: num, id: num };
    case 2:
      return { displayText: t('female'), value: num, id: num };
    case 0:
    case null:
      return { displayText: t('unknown'), value: num, id: num };
    default:
      throw new Error(`${num} cannot be mapped to a Gender`);
  }
};

const Customer = () => {
  const { t } = useTranslation('customer');
  const { t: tCommon } = useTranslation('common');
  const { id } = useParams();
  const [isEditingCustomerInfo, setIsEditingCustomerInfo] = useState(false);
  const navigate = useNavigate();
  const currentRoute = `${CUSTOMERS_ROUTE}/${id}`;

  const syncCustomerHash = '#sync';

  const [getCustomer, rCustomer, setRCustomer] = CustomerService.useGetCustomer();
  const [getConversionCustomer, rConversionCustomer] = CustomerAnalyticsService.useGetConversionCustomer();
  const [updateCustomer, rUpdatedCustomer, setRUpdatedCustomer] = CustomerService.useUpdateCustomer();
  const [getSubscriptions, subscriptions] = SubscriptionsService.useSubscriptionsService();
  const [getMedicalCase, rMedicalCase] = MedicalCaseService.useGetMedicalCaseByCustomerId();
  const [syncCustomerWithWoo, rSyncCustomerWithWoo, setSyncCustomerWithWooResult] =
    CustomerService.useSyncCustomerWithWoo();

  const genders = [1, 2, 0];

  const _getSubscriptions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: any) => {
      return getSubscriptions({ queries: { customerId: [{ value: id, operator: 'equals' }] }, ...params });
    },
    [getSubscriptions, id],
  );

  const customerSectionActions = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'right',
          paddingTop: '20px',
        }}
      >
        {!isEditingCustomerInfo ? (
          <Button onClick={() => setIsEditingCustomerInfo(true)}>
            <Icons.Edit />
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <BtnPrimary type="submit">{tCommon('save')}</BtnPrimary>
            <Button onClick={() => setIsEditingCustomerInfo(false)}>{tCommon('cancel')}</Button>
          </Box>
        )}
      </Box>
    );
  }, [isEditingCustomerInfo, tCommon]);

  const handleSubmitCustomerInfo = async (payload: CustomerModel, customerToUpdate: CustomerModel) => {
    const customerPayload: CustomerModel = {
      ...payload,
    };
    if (customerToUpdate.dateOfBirth !== payload.dateOfBirth) {
      customerPayload.dateOfBirth = DateTime.fromISO(payload.dateOfBirth).toFormat(_databaseFormat);
    }

    setRCustomer(loading());
    const updatedCustomer = await updateCustomer(customerPayload);
    setRCustomer(updatedCustomer);
    setIsEditingCustomerInfo(false);
  };

  useEffect(() => {
    getCustomer(id);
    getConversionCustomer(id);
    getMedicalCase(id);
  }, [id, getCustomer, getConversionCustomer, getMedicalCase]);

  return (
    <>
      {fold(
        () => <Loader />,
        () => <Loader />,
        (customer: CustomerModel) => (
          <>
            <ConnectedForm<Omit<CustomerModel, 'dateOfBirth'> & { dateOfBirth: DateTime | null }>
              defaultValues={{
                ...customer,
                dateOfBirth: customer.dateOfBirth ? DateTime.fromSQL(customer.dateOfBirth) : null,
              }}
              onSubmit={async (payload) =>
                handleSubmitCustomerInfo(
                  { ...payload, dateOfBirth: payload.dateOfBirth?.toFormat(_databaseFormat) || '' },
                  customer,
                )
              }
            >
              <Header title={`${t('customer')} #${customer.id}`}>{customerSectionActions}</Header>
              <Section title={t('info')}>
                <SectionContent>
                  <Info label={t('patientId')}>{customer.patientId || ''}</Info>
                  <Info label={t('wooCommerceId')}>
                    <Link
                      // eslint-disable-next-line max-len
                      href={`${process.env.REACT_APP_WOOCOMMERCE_URL}/user-edit.php?user_id=${customer?.woocommerceId}`}
                    >
                      {customer?.woocommerceId}
                    </Link>
                    <Btn
                      sx={{ width: '24px', minWidth: 0, margin: 0, padding: 0 }}
                      onClick={() => navigate(`${currentRoute}${syncCustomerHash}`)}
                    >
                      <Tooltip title={t('syncCustomerWithWoo')}>
                        <Icons.Sync />
                      </Tooltip>
                    </Btn>
                  </Info>
                  <Info label={t('medicalCaseId')}>
                    {withDefault(<span>--</span>)(
                      map((x: MedicalCaseModel) => <Link href={`${MEDICAL_CASE_ROUTE}/${x.id}`}>{x.id}</Link>)(
                        rMedicalCase,
                      ),
                    )}
                  </Info>
                </SectionContent>
                <SectionContent>
                  <Info label={t('updated')}>
                    {result.fold(
                      (e: string) => e,
                      (d: string) => d,
                    )(DateHelper.dateTime(customer.updated || new Date()))}
                  </Info>
                  <Info label={t('woocommerceCreate')}>
                    {result.fold(
                      (e: string) => e,
                      (d: string) => d,
                    )(DateHelper.dateTime(customer.dateCreated))}
                  </Info>
                  <Info label={t('woocommerceUpdated')}>
                    {result.fold(
                      (e: string) => e,
                      (d: string) => d,
                    )(DateHelper.dateTime(customer.dateUpdated))}
                  </Info>
                </SectionContent>
              </Section>
              <Section title={t('customer')}>
                {!isEditingCustomerInfo ? (
                  <>
                    <SectionContent>
                      <Info label={t('firstname')}>{customer.firstName}</Info>
                      <Info label={t('lastName')}>{customer.lastName}</Info>
                      <Info label={t('email')}>
                        <Link href={`mailto:${customer.email}`}>{customer.email}</Link>
                      </Info>
                      <Info label={t('dateOfBirth')}>
                        {result.fold(
                          (e: string) => e,
                          (d: string) => d,
                        )(DateHelper.onlyDateOfBirth(customer.dateOfBirth))}
                      </Info>
                    </SectionContent>
                    <SectionContent>
                      <SectionContent>
                        <Info label={t('gender')}>{genderFromNumber(tCommon, customer.gender).displayText}</Info>
                      </SectionContent>
                    </SectionContent>
                  </>
                ) : (
                  <SubFormSection>
                    <Form.Section title="">
                      <Form.Section.Row>
                        <TextInput name="firstName" readOnly label={t('firstname')}></TextInput>
                        <TextInput name="lastName" readOnly label={t('lastName')}></TextInput>
                        <EmailInput required name="email" label={t('email')} />
                        <DatePickerInput
                          readOnly={!isEditingCustomerInfo}
                          required
                          name="dateOfBirth"
                          label={t('dateOfBirth')}
                        />
                      </Form.Section.Row>
                      <Form.Section.Row md={3}>
                        <DropdownInput
                          items={genders}
                          label={t('gender')}
                          name="gender"
                          toOption={(item) => genderFromNumber(tCommon, item)}
                          readOnly={!isEditingCustomerInfo}
                          required
                        />
                      </Form.Section.Row>
                    </Form.Section>
                  </SubFormSection>
                )}
              </Section>

              {!isEditingCustomerInfo ? (
                <>
                  {customer.shippingAddress ? (
                    <Address title={t('shippingAddress')} address={customer.shippingAddress} />
                  ) : null}
                  <Address title={t('billingAddress')} address={customer.billingAddress} />
                </>
              ) : (
                <>
                  <FormAddress name="shippingAddress" title={t('shippingAddress')} />
                  <FormAddress name="billingAddress" title={t('billingAddress')} />
                </>
              )}
              <Section title={t('marketing')}>
                {withDefault(<span> {t('missingMarketingData')}</span>)(
                  map((conversionCustomer: ConversionCustomerModel) => (
                    <>
                      <SectionContent>
                        <Info label={t('utmCampaign')}>{conversionCustomer.utmCampaign || ''}</Info>
                        <Info label={t('utmContent')}>{conversionCustomer.utmContent || ''}</Info>
                        <Info label={t('utmMedium')}>{conversionCustomer.utmMedium || ''}</Info>
                        <Info label={t('utmSource')}>{conversionCustomer.utmSource || ''}</Info>
                        <Info label={t('utmTerm')}>{conversionCustomer.utmTerm || ''}</Info>
                      </SectionContent>
                      <SectionContent>
                        <Info label={t('est365Rev')}>{conversionCustomer.avgRev365 || ''}</Info>
                        <Info label={t('segmentScore')}>{conversionCustomer.segmentScore || ''}</Info>
                      </SectionContent>
                    </>
                  ))(rConversionCustomer),
                )}
              </Section>
              <Section title={t('subscriptions')}>
                <SubscriptionList
                  listId="subscription-customer-table"
                  getSubscriptions={_getSubscriptions}
                  subscriptions={subscriptions}
                  checkboxSelection={false}
                />
              </Section>
            </ConnectedForm>
            <ConfirmRoutedModal
              predicatedHash={syncCustomerHash}
              defaultUrl={currentRoute}
              title={t('syncCustomerWithWoo')}
              value="a"
              onConfirm={async (value, close) => {
                await syncCustomerWithWoo(customer.id);
                close();
              }}
            ></ConfirmRoutedModal>
          </>
        ),
        errorHandler,
      )(rCustomer)}

      <SuccessRemoteDataToast value={rUpdatedCustomer} onClick={() => setRUpdatedCustomer(notAsked())} />
      <SuccessRemoteDataToast
        value={rSyncCustomerWithWoo}
        onClick={() => {
          setSyncCustomerWithWooResult(notAsked());
        }}
      ></SuccessRemoteDataToast>
    </>
  );
};

export default Customer;
