import { useTranslation } from 'react-i18next';
import { DataTablePro as DataTable, Icons, Link, Title } from '../common/ui-component';
import { DateFormatter } from '../common/ui-component/data-table';
import CustomerService from './customer.service';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { CustomerModel } from '../models';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
const getActions = ({ id }: GridRowParams) => [
  <Link to={`/customers/${id}`}>
    <Icons.Edit />
  </Link>,
];

const Customers = () => {
  const { t } = useTranslation('customer');
  const [getCustomers, customers] = CustomerService.useGetCustomersV2();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('id'),
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'woocommerceId',
      headerName: t('customerId'),
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'patientId',
      headerName: t('patientId'),
      flex: 1,
      headerAlign: 'center',
      filterOperators: FilterOperator.getStringOrEmptyOperators(),
    },
    {
      field: 'firstName',
      headerName: t('firstName'),
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'lastName',
      headerName: t('lastName'),
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'phone',
      headerName: t('phone'),
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'gender',
      headerName: t('gender'),
      flex: 1,
      headerAlign: 'center',
      valueFormatter: ({ value }) => {
        if (value === 0) {
          return 'unknown';
        } else if (value === 1) {
          return 'Male';
        } else if (value === 2) {
          return 'Female';
        }
        return '';
      },
    },
    {
      field: 'dateOfBirth',
      headerName: t('dateOfBirth'),
      flex: 1,
      headerAlign: 'center',
      filterOperators: FilterOperator.getStringOrEmptyOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'dateCreated',
      headerName: t('dateCreated'),
      flex: 1,
      headerAlign: 'center',
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'dateCreatedWithTime',
      headerName: t('dateCreatedWithTime'),
      flex: 1,
      headerAlign: 'center',
      valueFormatter: DateFormatter.withTime,
      sortable: false,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions,
    },
  ];

  return (
    <>
      <Title>{t('customers')}</Title>
      <DataTable
        id={'customer-table'}
        cols={columns}
        fetcher={getCustomers}
        datas={customers}
        initialColumnVisibilityModel={{ dateCreatedWithTime: false }}
        toRow={(customer: CustomerModel) => ({
          ...customer,
          dateCreatedWithTime: customer.dateCreated,
          headerAlign: 'center',
        })}
        checkboxSelection
      />
    </>
  );
};

export default Customers;
