import { GridFilterModel } from '@mui/x-data-grid';

export interface PageProps {
  operator?: 'contains' | 'equals';
  pageNumber: number;
  pageSize: number;
  orderBy?: string;
  order?: 'asc' | 'desc' | null | undefined;
  queries?: {
    [key: string]: { value: string | number | null | undefined; operator: Operator }[];
  };
}

export type SearchParams = { [key: string]: { value: string | number | null | undefined; operator: Operator }[] };

export type Operator =
  | 'contains'
  | 'equals'
  | 'is'
  | 'is-null'
  | 'has'
  | 'is-empty-object'
  | 'is-filled-array'
  | 'json-array-contains'
  | 'has-all-photos-uploaded'
  | 'before'
  | 'after'
  | 'onOrAfter'
  | 'onOrBefore'
  | 'on';

export const operatorsWithNoValue: Operator[] = ['is-null'];

export const filterModelToBackofficeQueries = (filterModel: GridFilterModel): SearchParams => {
  const queries = filterModel.items.reduce((qs: { [key: string]: unknown[] }, item) => {
    const { field, value, operator } = item;
    if (!item.value && !operatorsWithNoValue.some((x) => x === operator)) return qs;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const current = qs[field];
    return {
      ...qs,
      [field]: [...(current || []), { value: value, operator: operator }],
    };
  }, {});

  return queries as SearchParams;
};

export const booleanIntegerValueOptions = [
  { value: '1', label: 'true' },
  { value: '0', label: 'false' },
];
