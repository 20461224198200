import axios from 'axios';
import useService from '../common/useService';
import { Paginated } from '../common/Paginated';
import { RenewalReminder } from '../models';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';

const RenewalReminderService = {
  useSearch: () =>
    useService((params: PageProps) => axios.get<Paginated<RenewalReminder>>(`/renewal-reminder`, { params })),

  useReminder: () => useService((id: string) => axios.post(`/renewal-reminder/${id}/remind`)),
};

export default RenewalReminderService;
