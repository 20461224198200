import axios from 'axios';
import useService from '../common/useService';
import { Product } from './product.entity';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { Paginated } from '../common/Paginated';

const useGetProducts = () => useService(() => axios.get<Product[]>(`/products`));

const useSearchProducts = () =>
  useService((params: PageProps) => axios.get<Paginated<Product>>(`/products/search`, { params }));

const useSyncFromWoo = () => useService((id: string) => axios.post(`/woocommerce/product/${id}/sync`));

const ProductService = {
  useGetProducts,
  useSearchProducts,
  useSyncFromWoo,
};

export default ProductService;
