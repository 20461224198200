import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { PageProps } from '../common/ui-component';
import { PageProps as PagePropsPro } from '../common/ui-component/data-table/filters/backoffice-query';
import useService from '../common/useService';
import { CustomerModel } from '../models';

const useGetCustomer = () => useService((id: string) => axios.get<CustomerModel>(`/customers/${id}`));

const useSyncByWooId = () => useService((id: number) => axios.get<CustomerModel>(`/customers/sync/${id}`));

const useSyncById = () => useService((id: number) => axios.get<CustomerModel>(`/customers/${id}/sync`));

const useGetCustomers = () =>
  useService((params: PageProps) => {
    return axios.get<Paginated<CustomerModel>>('/customers', {
      params,
    });
  });

const useGetCustomersV2 = () =>
  useService((params: PagePropsPro) =>
    axios.get<Paginated<CustomerModel>>(`/v2/customers/`, {
      params,
    }),
  );

const useUpdateCustomer = () =>
  useService((payload: CustomerModel) => axios.put<CustomerModel>(`/customers/${payload.id}`, payload));

const useSyncCustomerWithWoo = () => useService((id: number) => axios.post(`/customers/${id}/sync-with-woocommerce`));

const useGetCustomerIdentityHash = () =>
  useService((id: number) => axios.get<string>(`/customers/${id}/identity-hash`));

const CustomerService = {
  useGetCustomer,
  useGetCustomers,
  useGetCustomersV2,
  useSyncByWooId,
  useSyncById,
  useUpdateCustomer,
  useSyncCustomerWithWoo,
  useGetCustomerIdentityHash,
};

export default CustomerService;
