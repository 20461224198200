import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridFilterModel,
  GridRowModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Paginated } from '../../Paginated';
import { fold, RemoteData } from '../../../utils/remote-data';
import { useAccessToken } from '../../../authentication';
import { isAdminUser } from '../../../authentication/auth';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import Filter from './filters';

const filterOperators = () => getGridStringOperators().filter((operator) => operator.value === 'equals');

export type Sort = SortParam | null;
export interface SortParam {
  orderBy: string;
  order: 'asc' | 'desc' | null | undefined;
}

export type SearchParams = { [key: string]: string };
export interface PageProps {
  [key: string]: string | number | null | undefined;
  operator?: 'contains' | 'equals';
  pageNumber: number;
  pageSize: number;
  orderBy?: string;
  order?: 'asc' | 'desc' | null | undefined;
}

export interface RowsState {
  page: number;
  pageSize: number;
  rows: GridRowModel[];
  loading: boolean;
  total: number;
  searchParams: SearchParams;
  sort: Sort;
}

interface Props<A> {
  cols: GridColDef[];
  fetcher: (pageProps: PageProps) => void;
  datas: RemoteData<Paginated<A>, unknown>;
  checkboxSelection?: boolean;
  toRow: (d: A) => GridRowModel;
  actions?: (params: GridRowParams) => JSX.Element;
  forceReload?: boolean;
  hasToolbar?: boolean;
  sx?: SxProps<Theme> | undefined;
  renameFilterFields?: {
    original: string;
    renameto: string;
    parse: (from: string) => unknown;
  };
  hideFooter?: boolean;
  id?: string;
}

function DataTable<A>(props: Props<A>) {
  const {
    cols,
    checkboxSelection,
    fetcher,
    datas,
    toRow,
    forceReload,
    id,
    renameFilterFields,
    hasToolbar: hasAction = true,
    hideFooter = false,
    sx,
  } = props;

  const [page_, setPage] = useState(0);
  const [pageSize_, setPageSize] = useState(20);
  const [rows_, setRows] = useState<{ [key: string]: unknown }[]>([]);
  const [loading_, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchParams_, setSearchParams] = useState({});
  const [sort_, setSort] = useState<Sort>(null);
  const [user] = useAccessToken();

  const memoFetcher = useCallback(
    (pageNumber: number, pageSize: number, searchParams?: SearchParams, sort?: Sort) => {
      fetcher({ pageNumber, pageSize, ...searchParams, ...sort });
    },
    [fetcher],
  );

  useEffect(() => {
    memoFetcher(page_ + 1, pageSize_, searchParams_, sort_);
    // do not remove force reload
  }, [memoFetcher, forceReload, page_, pageSize_, searchParams_, sort_]);

  useEffect(() => {
    return fold(
      () => {
        setLoading(true);
      },
      () => {
        setLoading(true);
      },
      (data: Paginated<A>) => {
        setLoading(false);
        setRows(data.data.map(toRow));
        setPage(+data.pagination.currentPage - 1);
        setTotal(data.pagination.total);
      },
      () => {
        setLoading(false);
      },
    )(datas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  const onFilterChange = useCallback(
    (filterModel: GridFilterModel) => {
      const searchParams = filterModel.items.reduce((searchParam, { field, value, operator }) => {
        if (renameFilterFields) {
          const renamedColumn: { [x: string]: unknown } =
            renameFilterFields.original === field
              ? {
                  [renameFilterFields.renameto]:
                    value !== undefined && value !== '' ? renameFilterFields.parse(value) : value,
                }
              : { [field]: value };
          return {
            ...searchParam,
            ...renamedColumn,
            operator: operator,
          };
        }
        if (!value) return searchParam;

        return {
          ...searchParam,
          [field]: value,
          operator: operator,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          queries: { ...((searchParam as any).queries || {}), ...Filter.apply({ field, value, operator }) },
        };
      }, {});

      setSearchParams(searchParams);
    },
    [renameFilterFields],
  );

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sorts = sortModel.map((sortM) => ({
      orderBy: sortM.field,
      order: sortM.sort,
    }));
    setSort(sorts[0]);
  }, []);

  const columns = useMemo(
    (): GridColDef[] =>
      cols.map((col) => ({
        ...col,
        align: 'center',
        headerAlign: 'center',
        filterOperators: col.filterOperators || filterOperators(),
      })),
    [cols],
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {isAdminUser(user) && <GridToolbarExport />}
      </GridToolbarContainer>
    );
  }

  return (
    <DataGrid
      sx={sx}
      columns={columns}
      rowCount={total}
      paginationModel={{ pageSize: pageSize_, page: page_ }}
      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize === pageSize_) {
          setPage(pagination.page);
          return { pageSize: pageSize_, page: pagination.page };
        }
        setPage(pagination.page);
        setPageSize(pagination.pageSize);
        return { pageSize: pageSize_, page: page_ };
      }}
      checkboxSelection={checkboxSelection}
      paginationMode="server"
      slots={{ toolbar: hasAction ? CustomToolbar : undefined }}
      hideFooter={hideFooter}
      pagination
      autoHeight
      rows={rows_}
      loading={loading_}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      getRowId={(row) => row[id || 'id']}
    />
  );
}

export default DataTable;
