import { result } from '../../../../utils/result';
import { DateHelper } from '../../../../utils/date';

const dateFormatter = (value: string | null | undefined) => {
  const format = (date: Date) => {
    const yymmdd = result.fold(
      (e: string) => e,
      (d: string) => d,
    )(DateHelper.onlyDate(date));
    return yymmdd;
  };
  if (typeof value === 'string') return format(new Date(value));

  return '';
};

const dateFormatterWithTime = (value: string | null | undefined) => {
  const format = (date: Date) => {
    const yymmdd = result.fold(
      (e: string) => e,
      (d: string) => d,
    )(DateHelper.dateTime(date));
    return yymmdd;
  };
  if (typeof value === 'string') return format(new Date(value));

  return '';
};

const dateFormatterDOB = (value: string | null | undefined) => {
  if (typeof value === 'string') return new Date(value).toLocaleDateString(navigator.language);

  return '';
};

const DateFormatter = {
  normalize: dateFormatter,
  withTime: dateFormatterWithTime,
  dateOfBirth: dateFormatterDOB,
};

export default DateFormatter;
