type FilterOperator = 'contains' | 'equals' | 'is' | 'has' | 'null' | string | undefined;

export type ServerOperator = 'contains' | 'equals' | 'is';

type Query = { [key: string]: { value: string | number | null | undefined; operator: ServerOperator }[] };

const apply = ({ field, value, operator }: { field: string; value: string; operator: FilterOperator }): Query => {
  switch (operator) {
    case 'has': {
      if (value === '') {
        return { [field]: [] };
      } else if (value === 'true') {
        return { [field]: [{ value: '[{%]', operator: 'contains' }] };
      } else {
        return {
          [field]: [
            { value: '[]', operator: 'contains' },
            { value: null, operator: 'is' },
          ],
        };
      }
    }

    case 'null': {
      if (value === '') return { [field]: [] };
      else if (value === 'true') return { [field]: [{ value: '', operator: 'contains' }] };
      else return { [field]: [{ value: null, operator: 'is' }] };
    }

    case 'object': {
      if (value === '') {
        return { [field]: [] };
      } else if (value === 'true') {
        return { [field]: [{ value: '{"', operator: 'contains' }] };
      } else {
        return {
          [field]: [
            { value: '[]', operator: 'contains' },
            { value: '{}', operator: 'contains' },
            { value: null, operator: 'is' },
          ],
        };
      }
    }
    default:
      if (operator) return { [field]: [{ value, operator: operator as ServerOperator }] };
      else return { [field]: [] };
  }
};

export default apply;
