const PRODUCT_EN = {
  category: 'Category',
  created: 'Date Created',
  createdWithTime: 'Date Created (with time)',
  products: 'Products',
  sku: 'Sku',
  name: 'Name',
  pharmacy: 'Pharmacy',
  mdiId: 'Mdi Id',
  mdiTitle: 'Mdi Title',
  wooCommerceId: 'Woo Id',
  hasImage: 'has Image',
};

export default PRODUCT_EN;
