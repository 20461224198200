const COMMON_EN = {
  save: 'Save',
  notAsked: 'Not Asked',
  redirect: 'You will be redirected soon',
  httpError_400: 'Bad Request',
  httpError_401: 'The username or password is incorrect',
  httpError_500: 'Something bad happened on the server',
  httpSuccess: 'Call has been successful',
  areYouSure: 'Are you sure you want to do this ?',
  confirm: 'Confirm',
  cancel: 'Cancel',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  male: 'Male',
  female: 'Female',
  invalidEmail: 'Invalid Email Address',
  invalidPhoneNumber: 'Invalid Phone Number',
  required: 'Required',
  unknown: 'Other',
  weird: 'Something Weird happen close and retry',
  export: 'Export',
  tracking: 'Tracking',
};

export default COMMON_EN;
