import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Paginated } from '../common/Paginated';
import { DataTablePro as DataTable, Icons, Link } from '../common/ui-component';
import { DateFormatter } from '../common/ui-component/data-table';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { FeatureToggleHelper } from '../utils/feature-toggle';
import { RemoteData } from '../utils/remote-data';
import { PharmacyOrder, RxItem } from './pharmacy-order.model';
import StatusChip from './StatusChip';
import { PageProps, booleanIntegerValueOptions } from '../common/ui-component/data-table/filters/backoffice-query';

const getActions = ({ row }: GridRowParams<PharmacyOrder>) => {
  if (FeatureToggleHelper.isOff(process.env.REACT_APP_PHARMACY_DETAIL_TOGGLER)) return [];
  return [
    <Link to={`/pharmacy-order/${row.id}`}>
      <Icons.Edit />
    </Link>,
  ];
};

const getColumns = (t: (key: string) => string, filterOut: string[]): GridColDef[] => {
  const cols: GridColDef[] = [
    { field: 'orderId', headerName: t('orderId'), minWidth: 70 },
    { field: 'version', headerName: t('version'), minWidth: 65 },
    { field: 'pharmacyName', headerName: t('pharmacy'), minWidth: 95 },
    {
      field: 'status',
      headerName: t('status'),
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => <StatusChip status={params.row.status} />,
    },
    { field: 'statusDetails', headerName: t('statusDetails'), minWidth: 150 },
    { field: 'patientId', headerName: t('patientId'), minWidth: 100 },
    { field: 'firstName', headerName: t('firstName'), minWidth: 100 },
    { field: 'lastName', headerName: t('lastName'), minWidth: 100 },
    {
      field: 'clinician',
      headerName: 'hasClinicianAssigned',
      renderHeader: () => (
        <Tooltip title={t('clinicianAssigned')}>
          <span>{t('hasClinicianAssigned')}</span>
        </Tooltip>
      ),
      filterOperators: FilterOperator.getGridIsEmptyObjectOperators(),
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.row.clinician ? <Icons.Checked /> : <Icons.Cancel />),
    },
    {
      field: 'hasBeenApprovedByDoctor',
      renderHeader: () => (
        <Tooltip title={t('approved')}>
          <span>{t('wasApproved')}</span>
        </Tooltip>
      ),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) =>
        params.row.otcItems.length ? <Icons.Gray /> : params.value ? <Icons.Checked /> : <Icons.Cancel />,
    },
    {
      field: 'hasBeenPayed',
      renderHeader: () => (
        <Tooltip title={t('paid')}>
          <span>{t('wasPaid')}</span>
        </Tooltip>
      ),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
    },
    {
      field: 'rxItems',
      renderHeader: () => (
        <Tooltip title={t('hasPrescriptions')}>
          <span>{t('prescriptions')}</span>
        </Tooltip>
      ),
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) =>
        params.row.otcItems.length ? (
          <Icons.Gray />
        ) : params.row.rxItems.every((x: RxItem) => x.prescription) ? (
          <Icons.Checked />
        ) : (
          <Icons.Cancel />
        ),
    },
    {
      field: 'otcItems',
      renderHeader: () => (
        <Tooltip title={t('hasOverTheCounter')}>
          <span>{t('overTheCounter')}</span>
        </Tooltip>
      ),
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) =>
        params.row.rxItems.length ? <Icons.Gray /> : params.value.length ? <Icons.Checked /> : <Icons.Cancel />,
    },
    {
      field: 'hasAlreadyBeenShipped',
      renderHeader: () => (
        <Tooltip title={t('notShipped')}>
          <span>{t('shipped')}</span>
        </Tooltip>
      ),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Cancel /> : <Icons.Checked />),
    },
    {
      field: 'hasBeenCancelled',
      renderHeader: () => (
        <Tooltip title={t('notCancelled')}>
          <span>{t('notCancelled')}</span>
        </Tooltip>
      ),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Cancel /> : <Icons.Checked />),
    },
    {
      field: 'trackingNumber',
      headerName: t('trackingNumber'),
    },
    {
      field: 'created',
      headerName: t('created'),

      minWidth: 100,
      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'createdWithTime',
      headerName: t('createdWithTime'),

      minWidth: 100,
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'updated',
      headerName: t('updated'),

      minWidth: 100,
      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'updatedWithTime',
      headerName: t('updatedWithTime'),

      minWidth: 100,
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions,
    },
  ];

  const filteredCol = filterOut.length ? cols.filter((x) => !filterOut.some((y) => y === x.field)) : cols;

  return filteredCol;
};

interface Props {
  defaultParams?: PageProps;
  getOrders: (params?: PageProps | undefined) => Promise<RemoteData<Paginated<PharmacyOrder>, unknown>>;
  orders: RemoteData<Paginated<PharmacyOrder>, unknown>;
  hasToolbar?: boolean;
  filterOut?: string[];
}

const PharmacyList = ({ defaultParams, getOrders, orders, hasToolbar = true, filterOut = [] }: Props) => {
  const { t } = useTranslation('pharmacy');

  const fetcher = useCallback(
    (param: PageProps) => getOrders({ ...defaultParams, ...param }),
    [getOrders, defaultParams],
  );

  return (
    <DataTable
      id="pharmacy-table"
      cols={getColumns(t, filterOut)}
      fetcher={fetcher}
      datas={orders}
      toRow={(order: PharmacyOrder) => ({ ...order, createdWithTime: order.created, updatedWithTime: order.updated })}
      toCsvRow={(order: PharmacyOrder) => ({
        ...order,
        rxItems: order.otcItems?.length
          ? 'N/A'
          : (order.rxItems || []).every((x: RxItem) => x.prescription)
          ? 'TRUE'
          : 'FALSE',
        otcItems: order.otcItems.length ? 'N/A' : order.otcItems ? 'TRUE' : 'FALSE',
        clinician: !!order.clinician,
      })}
      initialColumnVisibilityModel={{ createdWithTime: false, updatedWithTime: false }}
      hasToolbar={hasToolbar}
    />
  );
};

export default PharmacyList;
